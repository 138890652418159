import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Star, StarOutline } from '@material-ui/icons';
import DynamicFeed from '@material-ui/icons/DynamicFeed';
import React from 'react';
import { ModalLayout } from '../../layouts/ModalLayout';
import { Order } from './Order';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  comment: {
    '& textarea': {
      color: 'black',
    },
  },
}));

interface FeedbackModalProps {
  order: Order;
  onClose: () => void;
}

export const FeedbackModal: React.FC<FeedbackModalProps> = ({
  order,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <ModalLayout>
      <Container component="main" maxWidth="xs">
        <div className="edit-category-modal">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <DynamicFeed />
            </Avatar>
            <Typography component="h1" variant="h5">
              Order Feedback
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={event => event.preventDefault()}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '20px',
                }}
              >
                {order.feedback &&
                  Array(5)
                    .fill(0)
                    .map((_, index) =>
                      index < order.feedback!.rating! ? (
                        <Star key={index} style={{ color: 'orange' }} />
                      ) : (
                        <StarOutline key={index} style={{ color: 'orange' }} />
                      )
                    )}
              </div>
              <TextField
                value={order.feedback?.comment ?? ''}
                classes={{ root: classes.comment }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="comment"
                label="Comment"
                name="comment"
                autoComplete="none"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                rows={8}
                disabled
              />

              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="default"
                className={classes.submit}
                onClick={onClose}
              >
                Close
              </Button>
            </form>
          </div>
        </div>
      </Container>
    </ModalLayout>
  );
};
