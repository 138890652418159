import React, { useEffect, useState } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import phones from '../../assets/img/Phones.png';
import logo from '../../assets/img/logo.svg';
import star from '../../assets/img/Star Icon.svg';
import playstore from '../../assets/img/google-play.png';
import appstore from '../../assets/img/apple.png';
import styles from './Intro.module.scss';
import { Loader } from '../../components/loader/Loader';

type Props = {};

export const Intro: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.addEventListener('load', event => {
      setTimeout(() => setIsLoading(prev => !prev), 2000);
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Grid container className={styles.intro} alignItems="center">
          <Grid item xs={12} md={5} className={styles.intro__info}>
            <div className={styles.intro__logo}>
              <img src={logo} alt="Vital Logo" />
              <div className={styles.intro__stars}>
                <img src={star} alt="Vital Logo" />
                <img src={star} alt="Vital Logo" />
                <img src={star} alt="Vital Logo" />
                <img src={star} alt="Vital Logo" />
                <img src={star} alt="Vital Logo" />
              </div>
            </div>
            <p className={styles.intro__text}>
              Vital connects clients and pharmacies in order to make it possible
              purchasing of online medicines and deliver them to the client's
              address.
            </p>
            <h1 className={styles.intro__call}>Download the app now</h1>
            <div className={styles.intro__download}>
              <button>
                <img src={playstore} alt="Play Store" />
                <div>
                  <span>ANDROID APP ON</span>
                  <h3>Google play</h3>
                </div>
              </button>
              <button>
                <img src={appstore} alt="App Store" />
                <div>
                  <span>Download ON</span>
                  <h3>App Store</h3>
                </div>
              </button>
            </div>
          </Grid>

          <Hidden smDown={true}>
            <Grid item md={4} lg={5}>
              <img
                src={phones}
                alt="Mobile View"
                className={styles.intro__phones}
              />
            </Grid>
          </Hidden>
        </Grid>
      )}
    </>
  );
};
