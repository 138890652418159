import { Card, CardContent, Typography } from '@material-ui/core';
import './StatisticCard.scss';

interface StatisticCardProps {
  title: string;
  data: number;
  color: 'red' | 'orange' | 'green' | 'blue';
}

export const StatisticCard: React.FC<StatisticCardProps> = ({
  title,
  data,
  color,
}) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <div className={`hr ${color}`}> </div>
        <div className={'title'}>
          <Typography align="left">{title}</Typography>
          <Typography variant="h5" align="left">
            {data}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};
