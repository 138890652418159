import React from 'react';
import { AppContextProvider } from '../contexts/AppContext';
import { AuthContextProvider } from '../contexts/AuthContext';
import { AxiosContextProvider } from '../contexts/AxiosContext';
import { SoundContextProvider } from '../contexts/SoundContext';

export const Providers: React.FC = ({ children }) => {
  return (
    <AppContextProvider>
      <AxiosContextProvider>
        <AuthContextProvider>
          <SoundContextProvider>{children}</SoundContextProvider>
        </AuthContextProvider>
      </AxiosContextProvider>
    </AppContextProvider>
  );
};
