import {
  createStyles,
  Fab,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AskApprovalModal } from '../../components/common/AskApprovalModal';
import TablePaginationActions from '../../components/common/TablePaginationActions';
import { AxiosContext } from '../../contexts/AxiosContext';
import DrawerLayout from '../../layouts/DrawerLayout';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteOutline } from '@material-ui/icons';
import { ProductModal } from './ProductModal';
import { Product } from './Product';
import { Category } from '../categories/Category';
import { useHistory, useLocation } from 'react-router';
import { AuthContext } from '../../contexts/AuthContext';
import styles from './Products.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    table: {
      minWidth: 500,
    },
    lastCell: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    text: {
      width: '48px',
      textAlign: 'right',
    },
    void: {
      width: '48px',
    },
    image: {
      width: '70px',
      height: '70px',
      backgroundColor: 'lightgrey',
      alignItems: 'center',
      borderRadius: '10px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    },
    categoryTag: {
      backgroundColor: 'lightgrey',
      borderRadius: '15px',
      paddingTop: '6px;',
      paddingBottom: '6px;',
      paddingLeft: '10px;',
      paddingRight: '10px;',
      margin: '5px',
    },
    actionContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
  })
);

interface Pagination {
  pages: number;
  page: number;
  size: number;
  length: number;
}

export const Products: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const classes = useStyles();
  const axios = useContext(AxiosContext);
  const { role } = useContext(AuthContext);

  const [page, setPage] = React.useState(() =>
    params.has('page') ? parseInt(params.get('page')!, 10) : 1
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(() =>
    params.has('size') ? parseInt(params.get('size')!, 10) : 10
  );

  const [search, setSearch] = React.useState<string>(() =>
    decodeURIComponent(params.get('search') ?? '')
  );
  const [selectedId, setSelectedId] = React.useState(0);
  const [pagination, setPagination] = React.useState<Pagination>({
    pages: 0,
    page: 1,
    size: 0,
    length: 0,
  });
  const [refresh, setRefresh] = useState<number>(() => Date.now());
  const [timestamp, setTimestamp] = useState<number>(() => Date.now());
  const [categories, setCategories] = useState<Category[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoadingProducts, setLoadingProducts] = useState<boolean>(true);
  const [isLoadingCategories, setLoadingCategories] = useState<boolean>(true);
  const isLoading = useMemo(() => {
    return isLoadingCategories || isLoadingProducts;
  }, [isLoadingCategories, isLoadingProducts]);
  const [error, setError] = useState<
    Error & { response?: { status: number } }
  >();

  const [showAddModal, setShowAddModal] = React.useState<boolean>(false);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  useEffect(() => {
    const newParams: Record<string, string> = {
      page: page.toString(),
      size: rowsPerPage.toString(),
    };
    if (params.has('categoryId')) {
      newParams.categoryId = params.get('categoryId')!;
    }
    if (search) {
      newParams.search = encodeURIComponent(search);
    }
    history.replace({
      pathname: '/products',
      search: new URLSearchParams(newParams).toString(),
    });
  }, [page, rowsPerPage, search]);

  useEffect(() => {
    setLoadingCategories(true);
    axios
      .get('/categories')
      .then(({ data }) => {
        setCategories(data);
      })
      .catch(setError)
      .finally(() => setLoadingCategories(false));
  }, []);

  useEffect(() => {
    setLoadingProducts(true);
    axios
      .get('/products', {
        params: {
          categoryId: params.get('categoryId') ?? undefined,
          page,
          size: rowsPerPage,
          search: search ? search : undefined,
        },
      })
      .then(({ data: { pagination, data } }) => {
        setProducts(data);
        setPagination(pagination);
      })
      .catch(setError)
      .finally(() => setLoadingProducts(false));
  }, [params.get('categoryId'), page, rowsPerPage, refresh, search]);

  const remove = (id: number) => {
    setLoadingProducts(true);
    axios
      .delete(`/products/${id}`)
      .then(() => {
        products.splice(
          products.findIndex(product => product.id === id),
          1
        );
        setProducts([...products]);
      })
      .catch(setError)
      .finally(() => setLoadingProducts(false));
  };

  return (
    <DrawerLayout pageTitle="Products">
      {error ? (
        <p style={{ color: 'red' }}>
          There was a problem. Please try again later.
        </p>
      ) : (
        <TableContainer>
          <Table aria-label="Products table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <TextField
                    value={search}
                    onChange={event => {
                      setPage(1);
                      setSearch(event.target.value);
                    }}
                    variant="standard"
                    required
                    label="Search..."
                    name="name"
                    autoFocus
                    autoComplete="none"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Price</TableCell>
                <TableCell align="center">Categories</TableCell>
                <TableCell align="right">
                  <Fab
                    color="primary"
                    aria-label="add"
                    size="small"
                    onClick={() => setShowAddModal(true)}
                  >
                    <AddIcon />
                  </Fab>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && products.length === 0 ? (
                <p style={{ color: 'orange' }}>There are no products.</p>
              ) : (
                products.map(product => (
                  <TableRow key={product.name} className={classes.root}>
                    <TableCell align="center">
                      <div
                        className={classes.image}
                        style={{
                          backgroundImage: `url(${
                            product.image
                              ? `${
                                  process.env.REACT_APP_PUBLIC_BASE_URL
                                }/products/${product.image}${
                                  product.id === selectedId
                                    ? `?${timestamp}`
                                    : ''
                                }`
                              : ''
                          })`,
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">{product.name}</TableCell>
                    <TableCell align="center">
                      {product.description.substr(0, 50)}
                      {product.description.length > 50 ? '...' : ''}
                    </TableCell>
                    <TableCell align="center">
                      <p style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                        {product.price}L
                      </p>
                    </TableCell>
                    <TableCell align="center">
                      <Grid container>
                        {product.categories.map(({ name }) => (
                          <Grid item>
                            <p className={classes.categoryTag} key={name}>
                              {name}
                            </p>
                          </Grid>
                        ))}
                      </Grid>
                    </TableCell>
                    <TableCell align="right">
                      <div className={classes.actionContainer}>
                        <IconButton
                          onClick={() => {
                            setSelectedId(product.id);
                            setShowEditModal(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        {role === 'ADMIN' && (
                          <IconButton
                            onClick={() => {
                              setSelectedId(product.id);
                              setShowDeleteModal(true);
                            }}
                          >
                            <DeleteOutline />
                          </IconButton>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  align="right"
                  rowsPerPageOptions={[10, 25]}
                  colSpan={6}
                  count={pagination.length}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={(_, page) => {
                    setPage(page + 1);
                  }}
                  onRowsPerPageChange={event => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(1);
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>

          <Modal
            open={showAddModal}
            onClose={() => setShowAddModal(false)}
            className={styles.modal}
          >
            <ProductModal
              categories={categories}
              onSuccess={() => {
                setShowAddModal(false);
                setRefresh(Date.now());
              }}
            />
          </Modal>

          <Modal
            open={showEditModal}
            onClose={() => setShowEditModal(false)}
            className={styles.modal}
          >
            <ProductModal
              product={products.find(product => product.id === selectedId)}
              categories={categories}
              onSuccess={product => {
                products[
                  products.findIndex(product => product.id === selectedId)
                ] = product;
                setProducts([...products]);
                setShowEditModal(false);
                setTimestamp(Date.now());
              }}
            />
          </Modal>

          <Modal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
          >
            <AskApprovalModal
              action="delete"
              type="product"
              onAction={async approved => {
                if (approved) {
                  remove(
                    products.find(product => product.id === selectedId)!.id
                  );
                }
                setShowDeleteModal(false);
              }}
            />
          </Modal>
        </TableContainer>
      )}
    </DrawerLayout>
  );
};
