import axios, { AxiosInstance } from 'axios';
import React, { createContext, useMemo } from 'react';

export const AxiosContext = createContext<AxiosInstance>(
  new Proxy(
    (() => {
      throw new Error('Missing AxiosContext provider.');
    }) as unknown as AxiosInstance,
    {
      get() {
        throw new Error('Missing AxiosContext provider.');
      },
    }
  )
);

export const AxiosContextProvider: React.FC = ({ children }) => {
  const instance = useMemo(
    () =>
      axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
      }),
    []
  );
  return (
    <AxiosContext.Provider value={instance}>{children}</AxiosContext.Provider>
  );
};
