import React, { useContext } from 'react';
import { CssBaseline } from '@material-ui/core';
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { Login } from '../pages/login/Login';
import { Products } from '../pages/products/Products';
import { Categories } from '../pages/categories/Categories';
import { Home } from '../pages/home/Home';
import { AuthContext } from '../contexts/AuthContext';
import { Users } from '../pages/users/Users';
import { Orders } from '../pages/orders/Orders';
import { Ads } from '../pages/ads/Ads';
import { Offers } from '../pages/offers/Offers';
import { PrivacyPolicy } from '../pages/privacy-policy/PrivacyPolicy';
import { Pharmacies } from '../pages/pharmacies/Pharmacies';
import { Intro } from '../pages/intro/Intro';

export const routeFallbackByRole: { [role: string]: string } = {
  ADMIN: '/home',
  EDITOR: '/categories',
  SELLER: '/home',
  TRANSPORTER: '/orders',
  SUPPORT: '/orders',
};

export const Routes: React.FC = () => {
  const { isAuthenticated, role } = useContext(AuthContext);

  return (
    <Router>
      <CssBaseline />
      <Switch>
        <Route path="/" component={Intro} exact />
        <PrivateRoute path="/dashboard" component={Home} exact />
        <PrivateRoute path="/editors" exact>
          <Users key="editor" type="EDITOR" />
        </PrivateRoute>
        <PrivateRoute path="/sellers" exact>
          <Users key="seller" type="SELLER" />
        </PrivateRoute>
        <PrivateRoute path="/transporters" exact>
          <Users key="transporter" type="TRANSPORTER" />
        </PrivateRoute>
        <PrivateRoute path="/supporters" exact>
          <Users key="supporter" type="SUPPORT" />
        </PrivateRoute>
        <PrivateRoute path="/orders" component={Orders} exact />
        <PrivateRoute path="/products" component={Products} exact />
        <PrivateRoute path="/categories" component={Categories} exact />
        <PrivateRoute path="/ads" component={Ads} exact />
        <PrivateRoute path="/offers" component={Offers} exact />
        <PrivateRoute path="/pharmacies" component={Pharmacies} exact />
        <Route path="/login" component={Login} exact />
        <Route path="/privacy-policy" component={PrivacyPolicy} exact />
        <Route>
          <Redirect
            to={isAuthenticated ? routeFallbackByRole[role] : '/login'}
          />
        </Route>
      </Switch>
    </Router>
  );
};
