import { Card, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import DrawerLayout from '../../layouts/DrawerLayout';
import styles from './Home.module.scss';
import { StatisticCard } from './StatisticCard';
import { TopProduct } from './TopProduct';
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  LineSeries,
} from '@devexpress/dx-react-chart-material-ui';

interface TopProduct {
  id: number;
  name: string;
  unitsSold: number;
  soldPercentage: number;
}

const data = [
  { argument: 1, value: 5 },
  { argument: 2, value: 19 },
  { argument: 3, value: 10 },
  { argument: 4, value: 5 },
  { argument: 5, value: 7 },
  { argument: 6, value: 9 },
  { argument: 7, value: 30 },
  { argument: 8, value: 56 },
  { argument: 9, value: 85 },
  { argument: 10, value: 25 },
  { argument: 11, value: 46 },
  { argument: 12, value: 48 },
  { argument: 13, value: 68 },
  { argument: 14, value: 32 },
  { argument: 15, value: 21 },
  { argument: 16, value: 11 },
  { argument: 17, value: 81 },
  { argument: 18, value: 122 },
  { argument: 19, value: 54 },
  { argument: 20, value: 37 },
  { argument: 21, value: 23 },
  { argument: 22, value: 18 },
  { argument: 23, value: 85 },
  { argument: 24, value: 37 },
  { argument: 25, value: 58 },
  { argument: 26, value: 66 },
  { argument: 27, value: 33 },
  { argument: 28, value: 60 },
  { argument: 29, value: 74 },
  { argument: 30, value: 88 },
];

export const Home: React.FC = () => {
  // const [topProducts, setTopProducts] = useState<TopProduct[]>([]);
  const [error, setError] = useState<
    Error & { response?: { status: number } }
  >();
  const [_, setLoading] = useState<boolean>();
  const topProducts = [
    { id: 1, name: 'product', unitsSold: 30, soldPercentage: 100 },
    { id: 2, name: 'product', unitsSold: 45, soldPercentage: 80 },
    { id: 3, name: 'product', unitsSold: 56, soldPercentage: 68 },
    { id: 4, name: 'product', unitsSold: 78, soldPercentage: 98 },
    { id: 5, name: 'product', unitsSold: 89, soldPercentage: 35 },
    { id: 6, name: 'product', unitsSold: 28, soldPercentage: 5 },
    { id: 7, name: 'product', unitsSold: 32, soldPercentage: 7 },
  ];
  // useEffect(() => {
  //   axios
  //     .get('/topProducts')
  //     .then(({ data }) => {
  //       setTopProducts(data);
  //     })
  //     .catch(setError)
  //     .finally(() => setLoading(false));
  // }, []);

  return (
    <DrawerLayout pageTitle="Home page">
      {error ? (
        <p style={{ color: 'red' }}>
          There was a problem. Please try again later.
        </p>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <StatisticCard title={'Daily sales'} data={3500} color={'red'} />
          </Grid>
          <Grid item xs={6} md={3}>
            <StatisticCard title={'Weekly sales'} data={2500} color={'green'} />
          </Grid>
          <Grid item xs={6} md={3}>
            <StatisticCard title={'Monthly sales'} data={3000} color={'blue'} />
          </Grid>
          <Grid item xs={6} md={3}>
            <StatisticCard
              title={'Weekly new customers'}
              data={150}
              color={'orange'}
            />
          </Grid>
          <Grid item xs={6} md={9}>
            <Card>
              <CardContent>
                <Typography variant="h4" className={styles.products__title}>
                  Sells
                </Typography>
                <Chart data={data}>
                  <ArgumentAxis />
                  <ValueAxis />

                  <LineSeries valueField="value" argumentField="argument" />
                </Chart>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card>
              <CardContent>
                <Typography variant="h4" className={styles.products__title}>
                  Top Products
                </Typography>
                {topProducts.map(product => (
                  <TopProduct
                    key={product.id}
                    name={product.name}
                    unitsSold={product.unitsSold}
                    soldPercentage={product.soldPercentage}
                  />
                ))}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </DrawerLayout>
  );
};
