import { Providers } from './Providers';
import { Routes } from './Routes';
import './App.scss';

const App = () => {
  return (
    <Providers>
      <Routes />
    </Providers>
  );
};

export default App;
