import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { Remove } from '@material-ui/icons';
import Edit from '@material-ui/icons/Edit';
import React, { useContext, useState } from 'react';
import { AxiosContext } from '../../contexts/AxiosContext';
import { ModalLayout } from '../../layouts/ModalLayout';
import { User } from '../users/User';
import { Order, Status } from './Order';
import statusInfo from './statusInfo';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(3, 0, 2),
    justifyContent: 'center',
  },
  image: {
    width: '150px',
    height: '150px',
    backgroundColor: 'whitesmoke',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  add: {
    width: '100%',
    height: '100%',
    color: 'lightgray',
    '&:hover': {
      color: 'silver',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  statusesSelectContainer: {
    marginTop: '16px',
  },
  statusesSelectRoot: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '19px !important',
    },
  },
  progressBar: {
    margin: '0 5px',
  },
}));

interface OrderModalProps {
  order: Order;
  transporters: User[];
  onSuccess: (order: Order) => void;
}

export const OrderModal: React.FC<OrderModalProps> = ({
  order: orderInit,
  transporters,
  onSuccess,
}) => {
  const classes = useStyles();
  const axios = useContext(AxiosContext);

  const [isLoading, setLoading] = useState<boolean>();
  const [error, setError] = useState<
    Error & { response?: { status: number } }
  >();

  const [transporterId, setTransporterId] = useState<number>(
    orderInit.transporter?.id ?? 0
  );
  const [status, setStatus] = useState<Status>(orderInit.status);

  const submit = () => {
    setError(undefined);
    setLoading(true);
    const data: { status?: Status; transporterId?: number | null } = {};
    if (transporterId !== (orderInit.transporter?.id ?? 0)) {
      data.transporterId = transporterId;
    } else if (status !== orderInit.status) {
      data.status = status;
    }
    axios
      .patch(`/orders/${orderInit.id.toString()}`, data)
      .then(({ data: order }) => onSuccess(order))
      .catch(setError)
      .finally(() => setLoading(false));
  };

  return (
    <ModalLayout>
      <Container component="main" maxWidth="xs">
        <div className="edit-order-modal">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <Edit />
            </Avatar>
            <Typography component="h1" variant="h5">
              Edit Order
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={event => event.preventDefault()}
            >
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.statusesSelectContainer}
              >
                <InputLabel shrink id="statuses-label">
                  Status *
                </InputLabel>
                <Select
                  classes={{
                    root: classes.statusesSelectRoot,
                  }}
                  labelId="statuses-label"
                  id="statuses"
                  label="Status * "
                  value={
                    transporterId !== (orderInit.transporter?.id ?? 0)
                      ? Status.CONFIRMING
                      : status
                  }
                  onChange={event => setStatus(event.target.value as Status)}
                  disabled={transporterId !== (orderInit.transporter?.id ?? 0)}
                >
                  {Object.values(Status).map(status => (
                    <MenuItem key={status} value={status}>
                      {statusInfo[status].Icon({
                        paddingRight: '10px',
                        fontSize: '28px',
                        color: 'black',
                      })}
                      <span>{status}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                fullWidth
                variant="outlined"
                className={classes.statusesSelectContainer}
              >
                <InputLabel shrink id="statuses-label">
                  Transporter *
                </InputLabel>
                <Select
                  classes={{
                    root: classes.statusesSelectRoot,
                  }}
                  labelId="statuses-label"
                  id="transporters"
                  label="Transporters * "
                  value={
                    [Status.PENDING, Status.ACCEPTED].includes(status)
                      ? 0
                      : transporterId
                  }
                  onChange={event =>
                    setTransporterId(event.target.value as number)
                  }
                  disabled={[Status.PENDING, Status.ACCEPTED].includes(status)}
                >
                  {([Status.PENDING, Status.ACCEPTED].includes(status) ||
                    !orderInit.transporter) && (
                    <MenuItem value={0}>
                      <span>
                        <Remove />
                      </span>
                    </MenuItem>
                  )}
                  {transporters.map(transporter => (
                    <MenuItem key={transporter.id} value={transporter.id}>
                      <span>{transporter.email}</span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submit}
                disabled={
                  (orderInit.status === status &&
                    (orderInit.transporter?.id ?? 0) === transporterId) ||
                  isLoading
                }
              >
                {isLoading && (
                  <CircularProgress
                    className={classes.progressBar}
                    value={20}
                    size={20}
                    color="secondary"
                  />
                )}
                Save
              </Button>
              {error && (
                <p style={{ color: 'red' }}>
                  There was a problem. Please try again later.
                </p>
              )}
            </form>
          </div>
        </div>
      </Container>
    </ModalLayout>
  );
};
