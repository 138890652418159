import React, { useEffect, useRef } from 'react';
import { Card } from '@material-ui/core';
import styles from './ModalLayout.module.scss';

interface ModalLayoutProps {}

export const ModalLayout: React.FC<ModalLayoutProps> = ({ children }) => {
  const cardRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (cardRef.current) {
      const layoutHeight = cardRef.current.offsetHeight;
      const parentHeight = cardRef.current.parentElement?.offsetHeight ?? 0;
      if (layoutHeight < parentHeight) {
        const top = (parentHeight - layoutHeight) / 2;
        cardRef.current.style.marginTop = `${top}px`;
      }
    }
  }, [cardRef.current]);

  return (
    <Card ref={cardRef} className={styles.modalLayout}>
      {children}
    </Card>
  );
};
