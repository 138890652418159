import { Status, Wrapper } from '@googlemaps/react-wrapper';
import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import Map from '../../components/map/Map';
import Marker from '../../components/map/Marker';
import { AxiosContext } from '../../contexts/AxiosContext';
import { ModalLayout } from '../../layouts/ModalLayout';
import { Pharmacy } from './Pharmacy';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(3, 0, 2),
    justifyContent: 'center',
  },
  image: {
    width: '150px',
    height: '150px',
    backgroundColor: 'whitesmoke',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  add: {
    width: '100%',
    height: '100%',
    color: 'lightgray',
    '&:hover': {
      color: 'silver',
    },
  },
  statusesSelectContainer: {
    marginTop: '16px',
  },
  statusesSelectRoot: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '19px !important',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  progressBar: {
    margin: '0 5px',
  },
}));

interface PharmacyModalProps {
  pharmacy?: Pharmacy;
  onSuccess: (pharmacy: Pharmacy) => void;
}

export const PharmacyModal: React.FC<PharmacyModalProps> = ({
  pharmacy: pharmacyInit = {
    id: 0,
    name: '',
    image: '',
    link: '',
    address: '',
    latitude: 0,
    longitude: 0,
  },
  onSuccess,
}) => {
  const classes = useStyles();
  const axios = useContext(AxiosContext);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const imageLoaderRef = useRef<HTMLDivElement>(null);
  const googleMapAPIKey = 'AIzaSyDO4AjDs8LeCBPtmBmhlVMPndkXHBAvhSY';

  const [isLoading, setLoading] = useState<boolean>();
  const [error, setError] = useState<
    Error & { response?: { status: number } }
  >();

  const [pharmacy, setPharmacy] = useState<Pharmacy>(pharmacyInit);
  const [image, setImage] = useState<File>();
  const [imageAsDataUrl, setImageAsDataUrl] = useState<string>();
  const imageSrc = useMemo(
    () =>
      imageAsDataUrl
        ? imageAsDataUrl
        : pharmacyInit.image
        ? `${process.env.REACT_APP_PUBLIC_BASE_URL}/pharmacies/${pharmacyInit.image}`
        : '',
    [imageAsDataUrl, pharmacyInit]
  );

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onload = event => {
        setImageAsDataUrl(event.target?.result?.toString());
      };
      reader.readAsDataURL(image);
    } else {
      setImageAsDataUrl(undefined);
    }
  }, [image]);

  const changedFields = useMemo(() => {
    return Object.entries(pharmacy)
      .filter(
        ([name, value]) =>
          [
            'name',
            'image',
            'link',
            'address',
            'latitude',
            'longitude',
          ].includes(name) && value !== pharmacyInit[name as keyof Pharmacy]
      )
      .reduce((data, [name, value]) => {
        data[name as keyof Pharmacy] = value;
        return data;
      }, {} as Partial<Pharmacy>);
  }, [pharmacy]);

  const hasChangedFields = useMemo(
    () => Object.values(changedFields).length > 0,
    [changedFields]
  );

  const hasChangedImage = useMemo(() => image, [image]);

  const submit = async () => {
    setError(undefined);
    setLoading(true);
    try {
      let receivedPharmacy: any;
      if (hasChangedFields) {
        if (pharmacyInit.id) {
          const { data } = await axios.patch(
            `/pharmacies/${pharmacyInit.id.toString()}`,
            changedFields
          );
          receivedPharmacy = data;
        } else {
          const { data } = await axios.post(`/pharmacies`, {
            ...changedFields,
          });
          receivedPharmacy = data;
        }
      }
      if (image) {
        const form = new FormData();
        form.append('image', image);
        const { data } = await axios.patch(
          `/pharmacies/${(
            pharmacyInit.id || receivedPharmacy.id
          ).toString()}/image`,
          form
        );
        receivedPharmacy = data;
      }
      onSuccess(receivedPharmacy);
    } catch (error: any) {
      setError(error);
    }
    setLoading(false);
  };

  const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();

  const [zoom, setZoom] = React.useState(12);

  const [center, setCenter] = React.useState<google.maps.LatLngLiteral>({
    lat: pharmacy.latitude !== 0 ? pharmacy.latitude : 41.32776,
    lng: pharmacy.longitude !== 0 ? pharmacy.longitude : 19.818167,
  });
  const [clickedLocation, setClickedLocation] =
    React.useState<google.maps.LatLngLiteral>(center);

  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult>();
  const [newPharmacy, setNewPharmacy] = useState<Pharmacy>(pharmacyInit);

  const { ref, autocompleteRef } = usePlacesWidget<HTMLDivElement>({
    apiKey: googleMapAPIKey,
    options: {
      componentRestrictions: { country: 'al' },
      fields: [
        'address_components',
        'formatted_address',
        'geometry',
        'icon',
        'name',
      ],
      strictBounds: false,
      types: ['establishment'],
    },
    onPlaceSelected: place => {
      // setSelectedPlace(place);
      setPharmacy({
        ...pharmacy,
        name: place.name ?? '',
        address: place.formatted_address ?? '',
        latitude: Number(place.geometry?.location?.lat().toFixed(7)) ?? '',
        longitude: Number(place.geometry?.location?.lng().toFixed(7)) ?? '',
      });
      if (place.geometry?.location) {
        setCenter(place.geometry?.location?.toJSON());
        setClickedLocation(place.geometry?.location?.toJSON());
      }
    },
  });

  const onClick = async (e: google.maps.MapMouseEvent) => {
    setGeocoder(new window.google.maps.Geocoder());
    await geocoder
      ?.geocode({ location: e.latLng! })
      .then(response => {
        if (response.results[0].formatted_address.length > 0) {
          setClickedLocation(e.latLng!.toJSON());
          setPharmacy({
            ...pharmacy,
            address: response.results[0].formatted_address,
            latitude: Number(e.latLng!.toJSON().lat),
            longitude: Number(e.latLng!.toJSON().lng),
          });
        }
      })
      .catch(e => window.alert('Geocoder failed due to: ' + e));

    // setCenter(e.latLng!.toJSON());
  };

  const onIdle = (m: google.maps.Map) => {
    setZoom(m.getZoom()!);
  };

  const render = (status: Status) => {
    return <h1>{status}</h1>;
  };

  return (
    <ModalLayout>
      <Container component="main" maxWidth="xs">
        <div className="edit-pharmacy-modal">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              {pharmacyInit.id ? <EditIcon /> : <AddIcon />}
            </Avatar>
            <Typography component="h1" variant="h5">
              {pharmacyInit.id ? 'Edit' : 'Add'} Pharmacy
            </Typography>
            <input
              ref={fileInputRef}
              style={{ display: 'none' }}
              type="file"
              name="file"
              accept="image/*"
              onChange={event => {
                setError(undefined);
                setImage(event.target.files?.[0] ?? undefined);
              }}
            />
            <div
              ref={imageLoaderRef}
              className={classes.image}
              onClick={() => fileInputRef.current?.click()}
              style={{ backgroundImage: `url(${imageSrc})` }}
            >
              <AddIcon
                style={{
                  visibility: image || pharmacy.image ? 'hidden' : 'visible',
                }}
                className={classes.add}
              />
            </div>
            <form
              className={classes.form}
              noValidate
              onSubmit={event => event.preventDefault()}
            >
              <TextField
                value={pharmacy.name}
                onChange={event => {
                  setError(undefined);
                  setPharmacy({ ...pharmacy, name: event.target.value });
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Pharmacy name"
                name="name"
                autoFocus
                autoComplete="none"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                value={pharmacy.link}
                onChange={event => {
                  setError(undefined);
                  setPharmacy({
                    ...pharmacy,
                    link: event.target.value,
                  });
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="link"
                label="Link"
                name="link"
                autoComplete="none"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                value={pharmacy.address}
                onChange={event => {
                  setError(undefined);
                  setPharmacy({
                    ...pharmacy,
                    address: event.target.value,
                  });
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="address"
                label="Address"
                name="address"
                autoComplete="none"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />

              <TextField
                // onChange={event => {
                //   setPharmacy({
                //     ...pharmacy,
                //     address: event.target.value,
                //   });
                // }}
                type="text"
                inputRef={ref}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="autocomplete"
                label="Enter a place"
                name="location"
                style={{ marginBottom: '30px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                aria-autocomplete="none"
              />

              <div
                style={{
                  display: 'flex',
                  height: '400px',
                  flexDirection: 'column',
                }}
              >
                <Wrapper apiKey={googleMapAPIKey} render={render}>
                  <Map
                    center={center}
                    onClick={onClick}
                    onIdle={onIdle}
                    zoom={zoom}
                    style={{
                      flexGrow: '1',
                      height: '100%',
                    }}
                  >
                    <Marker position={clickedLocation} />
                  </Map>
                </Wrapper>
              </div>

              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submit}
                disabled={
                  (!hasChangedFields && !hasChangedImage) ||
                  !pharmacy.name ||
                  !pharmacy.link ||
                  !pharmacy.address ||
                  !pharmacy.latitude ||
                  !pharmacy.longitude ||
                  isLoading
                }
              >
                {isLoading && (
                  <CircularProgress
                    className={classes.progressBar}
                    value={20}
                    size={20}
                    color="secondary"
                  />
                )}
                {pharmacyInit.id ? 'Save' : 'Add'}
              </Button>
              {error && (
                <p style={{ color: 'red' }}>
                  There was a problem. Please try again later.
                </p>
              )}
            </form>
          </div>
        </div>
      </Container>
    </ModalLayout>
  );
};
