import React, { useContext, useState } from 'react';
import PublicLayout from '../../layouts/PublicLayout';
import {
  Typography,
  Avatar,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Button,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { AuthContext } from '../../contexts/AuthContext';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const Login: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { login, role } = useContext(AuthContext);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [error, setError] = useState<
    Error & { response?: { status: number } }
  >();
  const [loading, setLoading] = useState<boolean>();

  const submit = (email: string, password: string) => {
    setLoading(true);
    setError(undefined);
    login(email, password)
      .then(() => {
        setLoading(false);
        history.push('/dashboard');
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  };

  return (
    <PublicLayout>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              value={email}
              onChange={event => {
                setError(undefined);
                setEmail(event.target.value);
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              value={password}
              onChange={event => {
                setError(undefined);
                setPassword(event.target.value);
              }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={
                !!error ||
                loading ||
                !email ||
                password.length < 8 ||
                password.length > 30
              }
              onClick={() => submit(email, password)}
            >
              Sign In
            </Button>
            <Grid container>
              {error && (
                <p style={{ color: 'red' }}>
                  {error.response?.status === 401
                    ? 'Invalid credentials.'
                    : 'There was a problem. Please try again later.'}
                </p>
              )}
              {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
            </Grid>
          </form>
        </div>
      </Container>
    </PublicLayout>
  );
};
