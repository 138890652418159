import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import React, { useContext, useState } from 'react';
import { AxiosContext } from '../../contexts/AxiosContext';
import { ModalLayout } from '../../layouts/ModalLayout';
import { User } from './User';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(3, 0, 2),
    justifyContent: 'center',
  },
  image: {
    width: '150px',
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    marginTop: '20px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  add: {
    width: '100%',
    height: '100%',
    color: 'lightgray',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  progressBar: {
    margin: '0 5px',
  },
}));

interface UserModalProps {
  user?: User;
  type: 'SELLER' | 'TRANSPORTER' | 'EDITOR' | 'SUPPORT';
  onSuccess: (user: User) => void;
}

export const UserModal: React.FC<UserModalProps> = ({
  user: userInit = { id: 0, email: '', password: '' },
  type,
  onSuccess,
}) => {
  const classes = useStyles();
  const axios = useContext(AxiosContext);

  const [isLoading, setLoading] = useState<boolean>();
  const [error, setError] = useState<
    Error & { response?: { status: number } }
  >();

  const [email, setEmail] = useState<string>(userInit.email);
  const [password, setPassword] = useState<string>('');

  const submit = () => {
    setError(undefined);
    setLoading(true);
    let promise;
    if (userInit.id) {
      const data: { email?: string; password?: string } = {};
      if (email !== userInit.email) {
        data.email = email;
      }
      if (password) {
        data.password = password;
      }
      promise = axios.patch(`/users/${userInit.id.toString()}`, data);
    } else {
      promise = axios.post(`/users`, { email, password, role: type });
    }
    promise
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  };

  return (
    <ModalLayout>
      <Container component="main" maxWidth="xs">
        <div className="edit-category-modal">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <AddIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {userInit.id ? 'Edit' : 'Add'} User
            </Typography>
            <div className={classes.image}>
              <AccountCircle className={classes.add} />
            </div>
            <form
              className={classes.form}
              noValidate
              onSubmit={event => event.preventDefault()}
            >
              <TextField
                value={email}
                onChange={event => {
                  setError(undefined);
                  setEmail(event.target.value);
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="User email"
                name="email"
                autoFocus
                autoComplete="none"
                type="email"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                value={password}
                onChange={event => {
                  setError(undefined);
                  setPassword(event.target.value);
                }}
                variant="outlined"
                margin="normal"
                fullWidth
                id="password"
                label="Password"
                name="password"
                autoComplete="none"
                multiline
                type="password"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submit}
                disabled={
                  (email === userInit.email && password.length === 0) ||
                  (!userInit.id && password.length === 0) ||
                  (password.length !== 0 &&
                    (password.length < 8 || password.length > 30)) ||
                  isLoading
                }
              >
                {isLoading && (
                  <CircularProgress
                    className={classes.progressBar}
                    value={20}
                    size={20}
                    color="secondary"
                  />
                )}
                {userInit.id ? 'Save' : 'Add'}
              </Button>
              {error && (
                <p style={{ color: 'red' }}>
                  {error.response?.status === 409
                    ? 'This email has been used to register another account.'
                    : 'There was a problem. Please try again later.'}
                </p>
              )}
            </form>
          </div>
        </div>
      </Container>
    </ModalLayout>
  );
};
