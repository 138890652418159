import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AxiosContext } from './AxiosContext';

interface AuthContextValue {
  isAuthenticated: boolean;
  token: string;
  role: string;
  login: (email: string, password: string) => Promise<string>;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextValue>(
  new Proxy({} as AuthContextValue, {
    get() {
      throw new Error('Missing AuthContext provider.');
    },
  })
);

export const AuthContextProvider: React.FC = ({ children }) => {
  const axios = useContext(AxiosContext);
  const [token, setToken] = useState<string>(
    () => localStorage.getItem('token') ?? ''
  );
  const [role, setRole] = useState<string>(
    () => localStorage.getItem('role') ?? ''
  );
  const isAuthenticated = useMemo(() => !!token, [token]);
  const info = useRef({ token, isAuthenticated });
  info.current = { token, isAuthenticated };

  const login = (email: string, password: string): Promise<string> => {
    return axios
      .post('/authentication/login', {
        email,
        password,
      })
      .then(({ data: { token, role } }) => {
        localStorage.setItem('role', role);
        localStorage.setItem('token', token);
        setRole(role);
        setToken(token);
        return token;
      });
  };

  const logout = () => {
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    setRole('');
    setToken('');
  };

  useState(() => {
    axios.interceptors.request.use(config => {
      if (info.current.isAuthenticated) {
        config.headers.Token = info.current.token;
      }
      return config;
    });

    axios.interceptors.response.use(undefined, error => {
      if (info.current.isAuthenticated && error.response.status === 401) {
        localStorage.removeItem('token');
        setToken('');
      }
      return Promise.reject(error);
    });
  });

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        token,
        role,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
