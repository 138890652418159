import {
  createStyles,
  Fab,
  IconButton,
  makeStyles,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';
import { DeleteOutline, DoneOutlined } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import DynamicFeed from '@material-ui/icons/DynamicFeed';
import EditIcon from '@material-ui/icons/Edit';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { AskApprovalModal } from '../../components/common/AskApprovalModal';
import TablePaginationActions from '../../components/common/TablePaginationActions';
import { AuthContext } from '../../contexts/AuthContext';
import { AxiosContext } from '../../contexts/AxiosContext';
import DrawerLayout from '../../layouts/DrawerLayout';
import styles from './Categories.module.scss';
import { Category } from './Category';
import { CategoryModal } from './CategoryModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    table: {
      minWidth: 500,
    },
    lastCell: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    text: {
      width: '48px',
      textAlign: 'right',
    },
    void: {
      width: '48px',
    },
    image: {
      width: '70px',
      height: '70px',
      backgroundColor: 'lightgrey',
      alignItems: 'center',
      borderRadius: '10px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    },
  })
);

export const Categories: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const axios = useContext(AxiosContext);
  const { role } = useContext(AuthContext);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [search, setSearch] = React.useState<string>('');
  const [selectedId, setSelectedId] = React.useState(0);
  const [timestamp, setTimestamp] = useState<number>(() => Date.now());
  const [categories, setCategories] = useState<Category[]>([]);
  const [isLoading, setLoading] = useState<boolean>();
  const [error, setError] = useState<
    Error & { response?: { status: number } }
  >();

  const filteredCategories = useMemo(() => {
    if (search) {
      return categories.filter(
        category =>
          category.name.toLowerCase().includes(search.toLowerCase()) ||
          category.description.toLowerCase().includes(search.toLowerCase())
      );
    }
    return categories;
  }, [search, categories]);

  const [showAddModal, setShowAddModal] = React.useState<boolean>(false);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get('/categories')
      .then(({ data }) => {
        setCategories(data);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  const remove = (id: number) => {
    setLoading(true);
    axios
      .delete(`/categories/${id}`)
      .then(() => {
        categories.splice(
          categories.findIndex(category => category.id === id),
          1
        );
        setCategories([...categories]);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  };

  return (
    <DrawerLayout pageTitle="Categories">
      {error ? (
        <p style={{ color: 'red' }}>
          There was a problem. Please try again later.
        </p>
      ) : (
        <TableContainer>
          <Table aria-label="Categories table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <TextField
                    value={search}
                    onChange={event => setSearch(event.target.value)}
                    variant="standard"
                    required
                    label="Search..."
                    name="name"
                    autoFocus
                    autoComplete="none"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Promotion</TableCell>
                <TableCell align="right">
                  <Fab
                    color="primary"
                    aria-label="add"
                    size="small"
                    onClick={() => setShowAddModal(true)}
                  >
                    <AddIcon />
                  </Fab>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && categories.length === 0 ? (
                <p style={{ color: 'orange' }}>There are no categories.</p>
              ) : (
                filteredCategories.map(category => (
                  <TableRow key={category.name} className={classes.root}>
                    <TableCell align="center">
                      <div
                        className={classes.image}
                        style={{
                          backgroundImage: `url(${
                            category.image
                              ? `${
                                  process.env.REACT_APP_PUBLIC_BASE_URL
                                }/categories/${category.image}${
                                  category.id === selectedId
                                    ? `?${timestamp}`
                                    : ''
                                }`
                              : ''
                          })`,
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">{category.name}</TableCell>
                    <TableCell align="center">
                      {category.description.substr(0, 50)}
                      {category.description.length > 50 ? '...' : ''}
                    </TableCell>
                    <TableCell align="center">
                      {category.promotion && (
                        <DoneOutlined style={{ color: 'green' }} />
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => {
                          history.push(
                            `/products?categoryId=${category.id.toString()}`
                          );
                        }}
                      >
                        <DynamicFeed />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setSelectedId(category.id);
                          setShowEditModal(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      {role === 'ADMIN' && (
                        <IconButton
                          onClick={() => {
                            setSelectedId(category.id);
                            setShowDeleteModal(true);
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  align="right"
                  rowsPerPageOptions={[10, 25]}
                  colSpan={6}
                  count={filteredCategories.length}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={(_, page) => setPage(page + 1)}
                  onRowsPerPageChange={event => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(1);
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>

          <Modal
            className={styles.modal}
            open={showAddModal}
            onClose={() => setShowAddModal(false)}
          >
            <CategoryModal
              onSuccess={category => {
                setCategories([...categories, category]);
                setShowAddModal(false);
                setTimestamp(Date.now());
              }}
            />
          </Modal>

          <Modal
            className={styles.modal}
            open={showEditModal}
            onClose={() => setShowEditModal(false)}
          >
            <CategoryModal
              category={categories.find(category => category.id === selectedId)}
              onSuccess={category => {
                categories[
                  categories.findIndex(category => category.id === selectedId)
                ] = category;
                setCategories([...categories]);
                setShowEditModal(false);
                setTimestamp(Date.now());
              }}
            />
          </Modal>

          <Modal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
          >
            <AskApprovalModal
              action="delete"
              type="category"
              onAction={async approved => {
                if (approved) {
                  remove(
                    categories.find(category => category.id === selectedId)!.id
                  );
                }
                setShowDeleteModal(false);
              }}
            />
          </Modal>
        </TableContainer>
      )}
    </DrawerLayout>
  );
};
