import { Typography } from '@material-ui/core';
import styles from './TopProduct.module.scss';

interface TopProductProps {
  name: string;
  unitsSold: number;
  soldPercentage: number;
}

export const TopProduct: React.FC<TopProductProps> = ({
  name,
  unitsSold,
  soldPercentage,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <Typography
          align="left"
          variant="overline"
          style={{ fontFamily: 'italic', color: 'grey' }}
        >
          {soldPercentage}%
        </Typography>
        <Typography align="right">Sold {unitsSold} units</Typography>
      </div>
      <div className={styles.product}>
        <div
          className={styles.progress}
          style={{ width: `${soldPercentage}%` }}
        >
          <Typography>{name}</Typography>
        </div>
      </div>
    </div>
  );
};
