import React, { ReactElement, useContext, useState } from 'react';
import {
  AppBar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Toolbar,
  Typography,
  Drawer,
  useTheme,
  SvgIconTypeMap,
  makeStyles,
  createStyles,
  Theme,
  Menu,
  MenuItem,
  Container,
  Button,
} from '@material-ui/core';
import clsx from 'clsx';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Dashboard from '@material-ui/icons/Dashboard';
import EditIcon from '@material-ui/icons/Edit';
import AirportShuttle from '@material-ui/icons/AirportShuttle';
import RedeemIcon from '@material-ui/icons/Redeem';
import LocalOffer from '@material-ui/icons/LocalOffer';
import CategoryIcon from '@material-ui/icons/Category';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { Link } from 'react-router-dom';
import './DrawerLayout.scss';
import {
  AccountCircle,
  Headset,
  ImageOutlined,
  LocalHospitalOutlined,
  LocalPharmacyOutlined,
  Person,
  VolumeOff,
  VolumeUp,
} from '@material-ui/icons';
import Modal from '@material-ui/core/Modal';
import { CredentialsModal } from '../components/account/CredentialsModal';
import { AuthContext } from '../contexts/AuthContext';
import { SoundContext } from '../contexts/SoundContext';

interface DrawerLayoutProps {
  children: ReactElement<any, any>;
  pageTitle: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    title: {
      flexGrow: 1,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(8),
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(9),
    },
    container: {
      marginTop: '20px',
    },
  })
);

const drawerWidth = 240;

const list: {
  url: string;
  page: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  role: string[];
}[] = [
  {
    page: 'Home',
    icon: Dashboard,
    url: '/dashboard',
    role: ['ADMIN', 'SELLER'],
  },
  {
    page: 'Ads',
    icon: ImageOutlined,
    url: '/ads',
    role: ['ADMIN'],
  },
  {
    page: 'Offers',
    icon: LocalOffer,
    url: '/offers',
    role: ['ADMIN'],
  },
  {
    page: 'Categories',
    icon: CategoryIcon,
    url: '/categories',
    role: ['ADMIN', 'EDITOR'],
  },
  {
    page: 'Products',
    icon: RedeemIcon,
    url: '/products',
    role: ['ADMIN', 'EDITOR'],
  },
  {
    page: 'Orders',
    icon: LocalHospitalOutlined,
    url: '/orders',
    role: ['ADMIN', 'SELLER', 'TRANSPORTER', 'SUPPORT'],
  },
  {
    page: 'Pharmacies',
    icon: LocalPharmacyOutlined,
    url: '/pharmacies',
    role: ['ADMIN', 'SELLER', 'TRANSPORTER', 'SUPPORT'],
  },
  {
    page: 'Editors',
    icon: EditIcon,
    url: '/editors',
    role: ['ADMIN'],
  },
  {
    page: 'Sellers',
    icon: Person,
    url: '/sellers',
    role: ['ADMIN'],
  },
  {
    page: 'Transporters',
    icon: AirportShuttle,
    url: '/transporters',
    role: ['ADMIN'],
  },
  {
    page: 'Supporters',
    icon: Headset,
    url: '/supporters',
    role: ['ADMIN'],
  },
];

export default function DrawerLayout(props: DrawerLayoutProps) {
  const classes = useStyles();
  const { logout, role } = useContext(AuthContext);
  const theme = useTheme();
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  // const [soundOn, setSoundOn] = useState(true);
  const sound = useContext(SoundContext);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSoundClick:
    | React.MouseEventHandler<HTMLButtonElement>
    | undefined = () => {
    sound.setSoundOn(!sound.soundOn);
  };

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerIsOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="toggle drawer"
            onClick={() => setDrawerIsOpen(!drawerIsOpen)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" noWrap className={classes.title}>
            {props.pageTitle}
          </Typography>

          <IconButton color="inherit" onClick={handleSoundClick}>
            {sound.soundOn ? <VolumeUp /> : <VolumeOff />}
          </IconButton>

          <div>
            <Button
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <p style={{ paddingRight: '5px' }}>{role}</p>
              <AccountCircle />
            </Button>
            <Menu
              anchorEl={anchorEl}
              id="menu-appbar"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setShowChangePasswordModal(true);
                  handleClose();
                }}
              >
                Change password
              </MenuItem>
              <Modal
                open={showChangePasswordModal}
                onClose={() => setShowChangePasswordModal(false)}
              >
                <CredentialsModal
                  close={() => setShowChangePasswordModal(false)}
                />
              </Modal>

              <MenuItem
                onClick={() => {
                  logout();
                  handleClose();
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerIsOpen,
          [classes.drawerClose]: !drawerIsOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerIsOpen,
            [classes.drawerClose]: !drawerIsOpen,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={() => setDrawerIsOpen(false)}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {list
            .filter(item => item.role.includes(role))
            .map(({ page, icon: Icon, url }, index) => (
              <Link to={url} key={page} className="sidebar__link">
                <ListItem button key={page}>
                  <ListItemIcon>
                    {<Icon style={{ color: '#666666', marginLeft: '8px' }} />}
                  </ListItemIcon>
                  <ListItemText primary={page} />
                </ListItem>
              </Link>
            ))}
        </List>
      </Drawer>
      <main className={classes.content}>
        <Container classes={{ root: classes.container }} disableGutters>
          {props.children}
        </Container>
      </main>
    </>
  );
}
