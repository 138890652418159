import { Pharmacy } from './../pharmacies/Pharmacy';
import { Product } from '../products/Product';

interface Quantity {
  quantity: number;
  product: Product;
}

interface Feedback {
  comment: string;
  rating: number;
}

interface Device {
  phoneNumber: string;
}

export enum Status {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  CONFIRMING = 'CONFIRMING',
  REFUSED = 'REFUSED',
  PICKING = 'PICKING',
  DELIVERING = 'DELIVERING',
  DELIVERED = 'DELIVERED',
  DECLINED = 'DECLINED',
  FAILED = 'FAILED',
}

export interface Transporter {
  id: number;
  email: string;
}

export interface Order {
  id: number;
  quantities: Quantity[];
  feedback?: Feedback;
  status: Status;
  price: number;
  address: string;
  phoneNumber: string;
  device: Device;
  transporter?: Transporter;
  pharmacy?: Pharmacy;
}
