import {
  createStyles,
  Fab,
  IconButton,
  makeStyles,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AskApprovalModal } from '../../components/common/AskApprovalModal';
import TablePaginationActions from '../../components/common/TablePaginationActions';
import { AxiosContext } from '../../contexts/AxiosContext';
import DrawerLayout from '../../layouts/DrawerLayout';
import { Offer } from './Offer';
import { OfferModal } from './OfferModal';
import styles from './Offers.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    table: {
      minWidth: 500,
    },
    lastCell: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    text: {
      width: '48px',
      textAlign: 'right',
    },
    void: {
      width: '48px',
    },
    image: {
      width: '70px',
      height: '70px',
      backgroundColor: 'lightgrey',
      alignItems: 'center',
      borderRadius: '10px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    },
  })
);

export const Offers: React.FC = () => {
  const classes = useStyles();
  const axios = useContext(AxiosContext);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [search, setSearch] = React.useState<string>('');
  const [selectedId, setSelectedId] = React.useState(0);
  const [timestamp, setTimestamp] = useState<number>(() => Date.now());
  const [offers, setOffers] = useState<Offer[]>([]);
  const [isLoading, setLoading] = useState<boolean>();
  const [error, setError] = useState<
    Error & { response?: { status: number } }
  >();

  const filteredOffers = useMemo(() => {
    if (search) {
      return offers.filter(
        offer =>
          offer.name.toLowerCase().includes(search.toLowerCase()) ||
          offer.description.toLowerCase().includes(search.toLowerCase())
      );
    }
    return offers;
  }, [search, offers]);

  const [showAddModal, setShowAddModal] = React.useState<boolean>(false);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get('/offers')
      .then(({ data }) => {
        setOffers(data);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  const remove = (id: number) => {
    setLoading(true);
    axios
      .delete(`/offers/${id}`)
      .then(() => {
        offers.splice(
          offers.findIndex(offer => offer.id === id),
          1
        );
        setOffers([...offers]);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  };

  return (
    <DrawerLayout pageTitle="Offers">
      {error ? (
        <p style={{ color: 'red' }}>
          There was a problem. Please try again later.
        </p>
      ) : (
        <TableContainer>
          <Table aria-label="Offers table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <TextField
                    value={search}
                    onChange={event => setSearch(event.target.value)}
                    variant="standard"
                    required
                    label="Search..."
                    name="name"
                    autoFocus
                    autoComplete="none"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Discount</TableCell>
                <TableCell align="center">Coupon code</TableCell>
                <TableCell align="right">
                  <Fab
                    color="primary"
                    aria-label="add"
                    size="small"
                    onClick={() => setShowAddModal(true)}
                  >
                    <AddIcon />
                  </Fab>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && offers.length === 0 ? (
                <p style={{ color: 'orange' }}>There are no offers.</p>
              ) : (
                filteredOffers.map(offer => (
                  <TableRow key={offer.name} className={classes.root}>
                    <TableCell align="center">
                      <div
                        className={classes.image}
                        style={{
                          backgroundImage: `url(${
                            offer.image
                              ? `${
                                  process.env.REACT_APP_PUBLIC_BASE_URL
                                }/offers/${offer.image}${
                                  offer.id === selectedId ? `?${timestamp}` : ''
                                }`
                              : ''
                          })`,
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">{offer.name}</TableCell>
                    <TableCell align="center">
                      {offer.description.substr(0, 50)}
                      {offer.description.length > 50 ? '...' : ''}
                    </TableCell>
                    <TableCell align="center">{offer.discount}</TableCell>
                    <TableCell align="center">{offer.couponCode}</TableCell>
                    <TableCell align="right">
                      {/* <IconButton href={offer.link} target="_new">
                        <OpenInNewOutlined />
                      </IconButton> */}
                      <IconButton
                        onClick={() => {
                          setSelectedId(offer.id);
                          setShowEditModal(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setSelectedId(offer.id);
                          setShowDeleteModal(true);
                        }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  align="right"
                  rowsPerPageOptions={[10, 25]}
                  colSpan={6}
                  count={filteredOffers.length}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={(_, page) => setPage(page + 1)}
                  onRowsPerPageChange={event => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(1);
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>

          <Modal
            className={styles.modal}
            open={showAddModal}
            onClose={() => setShowAddModal(false)}
          >
            <OfferModal
              onSuccess={offer => {
                setOffers([...offers, offer]);
                setShowAddModal(false);
                setTimestamp(Date.now());
              }}
            />
          </Modal>

          <Modal
            className={styles.modal}
            open={showEditModal}
            onClose={() => setShowEditModal(false)}
          >
            <OfferModal
              offer={offers.find(offer => offer.id === selectedId)}
              onSuccess={offer => {
                offers[offers.findIndex(offers => offers.id === selectedId)] =
                  offer;
                setOffers([...offers]);
                setShowEditModal(false);
                setTimestamp(Date.now());
              }}
            />
          </Modal>

          <Modal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
          >
            <AskApprovalModal
              action="delete"
              type="offer"
              onAction={async approved => {
                if (approved) {
                  remove(offers.find(offer => offer.id === selectedId)!.id);
                }
                setShowDeleteModal(false);
              }}
            />
          </Modal>
        </TableContainer>
      )}
    </DrawerLayout>
  );
};
