import React, { createContext, useState } from 'react';

interface SoundContextValue {
  soundOn: boolean;
  setSoundOn: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SoundContext = createContext<SoundContextValue>(
  new Proxy({} as SoundContextValue, {
    get() {
      throw new Error('Missing SoundContextValue provider.');
    },
  })
);

export const SoundContextProvider: React.FC = ({ children }) => {
  const [soundOn, setSoundOn] = useState(true);

  return (
    <SoundContext.Provider
      value={{
        soundOn,
        setSoundOn,
      }}
    >
      {children}
    </SoundContext.Provider>
  );
};
