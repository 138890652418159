import React, { createContext, useMemo, useState } from 'react';

interface AppContextValue {
  error: Error | undefined;
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>;
  isLoading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
}

export const AppContext = createContext<AppContextValue>(
  new Proxy({} as AppContextValue, {
    get() {
      throw new Error('Missing AppContext provider.');
    },
  })
);

export const AppContextProvider: React.FC = ({ children }) => {
  const [error, setError] = useState<Error>();

  const [loading, setLoading] = useState<number>(0);
  const startLoading = () => setLoading(loading + 1);
  const stopLoading = () => setLoading(loading - 1);
  const isLoading = useMemo(() => loading > 0, [loading]);

  return (
    <AppContext.Provider
      value={{
        error,
        setError,
        isLoading,
        startLoading,
        stopLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
