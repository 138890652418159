import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AxiosContext } from '../../contexts/AxiosContext';
import { ModalLayout } from '../../layouts/ModalLayout';
import { Ad } from './Ad';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(3, 0, 2),
    justifyContent: 'center',
  },
  image: {
    width: '150px',
    height: '150px',
    backgroundColor: 'whitesmoke',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  add: {
    width: '100%',
    height: '100%',
    color: 'lightgray',
    '&:hover': {
      color: 'silver',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  progressBar: {
    margin: '0 5px',
  },
}));

interface AdModalProps {
  ad?: Ad;
  onSuccess: (ad: Ad) => void;
}

export const AdModal: React.FC<AdModalProps> = ({
  ad: adInit = {
    id: 0,
    name: '',
    description: '',
    image: '',
    link: '',
  },
  onSuccess,
}) => {
  const classes = useStyles();
  const axios = useContext(AxiosContext);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const imageLoaderRef = useRef<HTMLDivElement>(null);

  const [isLoading, setLoading] = useState<boolean>();
  const [error, setError] = useState<
    Error & { response?: { status: number } }
  >();

  const [ad, setAd] = useState<Ad>(adInit);
  const [image, setImage] = useState<File>();
  const [imageAsDataUrl, setImageAsDataUrl] = useState<string>();
  const imageSrc = useMemo(
    () =>
      imageAsDataUrl
        ? imageAsDataUrl
        : adInit.image
        ? `${process.env.REACT_APP_PUBLIC_BASE_URL}/ads/${adInit.image}`
        : '',
    [imageAsDataUrl, adInit]
  );

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onload = event => {
        setImageAsDataUrl(event.target?.result?.toString());
      };
      reader.readAsDataURL(image);
    } else {
      setImageAsDataUrl(undefined);
    }
  }, [image]);

  const changedFields = useMemo(() => {
    return Object.entries(ad)
      .filter(
        ([name, value]) =>
          ['name', 'description', 'link'].includes(name) &&
          value !== adInit[name as keyof Ad]
      )
      .reduce((data, [name, value]) => {
        data[name as keyof Ad] = value;
        return data;
      }, {} as Partial<Ad>);
  }, [ad]);

  const hasChangedFields = useMemo(
    () => Object.values(changedFields).length > 0,
    [changedFields]
  );

  const hasChangedImage = useMemo(() => image, [image]);

  const submit = async () => {
    setError(undefined);
    setLoading(true);
    try {
      let receivedAd: any;
      if (hasChangedFields) {
        if (adInit.id) {
          const { data } = await axios.patch(
            `/ads/${adInit.id.toString()}`,
            changedFields
          );
          receivedAd = data;
        } else {
          const { data } = await axios.post(`/ads`, changedFields);
          receivedAd = data;
        }
      }
      if (image) {
        const form = new FormData();
        form.append('image', image);
        const { data } = await axios.patch(
          `/ads/${(adInit.id || receivedAd.id).toString()}/image`,
          form
        );
        receivedAd = data;
      }
      onSuccess(receivedAd);
    } catch (error: any) {
      setError(error);
    }
    setLoading(false);
  };

  return (
    <ModalLayout>
      <Container component="main" maxWidth="xs">
        <div className="edit-ad-modal">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              {adInit.id ? <EditIcon /> : <AddIcon />}
            </Avatar>
            <Typography component="h1" variant="h5">
              {adInit.id ? 'Edit' : 'Add'} Ad
            </Typography>
            <input
              ref={fileInputRef}
              style={{ display: 'none' }}
              type="file"
              name="file"
              accept="image/*"
              onChange={event => {
                setError(undefined);
                setImage(event.target.files?.[0] ?? undefined);
              }}
            />
            <div
              ref={imageLoaderRef}
              className={classes.image}
              onClick={() => fileInputRef.current?.click()}
              style={{ backgroundImage: `url(${imageSrc})` }}
            >
              <AddIcon
                style={{
                  visibility: image || ad.image ? 'hidden' : 'visible',
                }}
                className={classes.add}
              />
            </div>
            <form
              className={classes.form}
              noValidate
              onSubmit={event => event.preventDefault()}
            >
              <TextField
                value={ad.name}
                onChange={event => {
                  setError(undefined);
                  setAd({ ...ad, name: event.target.value });
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Ad name"
                name="name"
                autoFocus
                autoComplete="none"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                value={ad.description}
                onChange={event => {
                  setError(undefined);
                  setAd({
                    ...ad,
                    description: event.target.value,
                  });
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="description"
                label="Description"
                name="description"
                autoComplete="none"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                maxRows={4}
              />

              <TextField
                value={ad.link}
                onChange={event => {
                  setError(undefined);
                  setAd({
                    ...ad,
                    link: event.target.value,
                  });
                }}
                variant="outlined"
                margin="normal"
                fullWidth
                id="link"
                label="Link"
                name="link"
                autoComplete="none"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submit}
                disabled={
                  (!hasChangedFields && !hasChangedImage) ||
                  !ad.name ||
                  !ad.description ||
                  isLoading
                }
              >
                {isLoading && (
                  <CircularProgress
                    className={classes.progressBar}
                    value={20}
                    size={20}
                    color="secondary"
                  />
                )}
                {adInit.id ? 'Save' : 'Add'}
              </Button>
              {error && (
                <p style={{ color: 'red' }}>
                  {error.response?.status === 413
                    ? 'Image is too big.'
                    : 'There was a problem. Please try again later.'}
                </p>
              )}
            </form>
          </div>
        </div>
      </Container>
    </ModalLayout>
  );
};
