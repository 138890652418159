import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Container,
  CssBaseline,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AxiosContext } from '../../contexts/AxiosContext';
import { ModalLayout } from '../../layouts/ModalLayout';
import { Category } from '../categories/Category';
import { Product } from './Product';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(3, 0, 2),
    justifyContent: 'center',
  },
  image: {
    width: '150px',
    height: '150px',
    backgroundColor: 'whitesmoke',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  add: {
    width: '100%',
    height: '100%',
    color: 'lightgray',
    '&:hover': {
      color: 'silver',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  chip: {
    margin: '2px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  categoriesLabel: {
    backgroundColor: 'white',
    paddingLeft: '6px',
    paddingRight: '8px',
    marginLeft: '-4px',
  },
  categoriesSelect: {
    background: 'white',
  },
  categoriesSelectContainer: {
    marginTop: '16px',
  },
  progressBar: {
    margin: '0 5px',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ProductModalProps {
  product?: Product;
  categories: Category[];
  onSuccess: (product: Product) => void;
}

export const ProductModal: React.FC<ProductModalProps> = ({
  product: productInit = {
    id: 0,
    name: '',
    description: '',
    guidelines: '',
    sideEffects: '',
    price: 0,
    discount: 0,
    image: '',
    categories: [],
  },
  categories,
  onSuccess,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const axios = useContext(AxiosContext);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const imageLoaderRef = useRef<HTMLDivElement>(null);

  const [isLoading, setLoading] = useState<boolean>();
  const [error, setError] = useState<
    Error & { response?: { status: number } }
  >();

  const [selectedCategories, setSelectedCategories] = useState<number[]>(() =>
    productInit.categories.map(category => category.id)
  );
  const [product, setProduct] = useState<Product>(productInit);
  const [image, setImage] = useState<File>();
  const [imageAsDataUrl, setImageAsDataUrl] = useState<string>();
  const imageSrc = useMemo(
    () =>
      imageAsDataUrl
        ? imageAsDataUrl
        : productInit.image
        ? `${process.env.REACT_APP_PUBLIC_BASE_URL}/products/${productInit.image}`
        : '',
    [imageAsDataUrl, productInit]
  );

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onload = event => {
        setImageAsDataUrl(event.target?.result?.toString());
      };
      reader.readAsDataURL(image);
    } else {
      setImageAsDataUrl(undefined);
    }
  }, [image]);

  const changedFields = useMemo(() => {
    return Object.entries(product)
      .filter(
        ([name, value]) =>
          [
            'name',
            'description',
            'guidelines',
            'sideEffects',
            'price',
            'discount',
          ].includes(name) && value !== productInit[name as keyof Product]
      )
      .reduce((data, [name, value]) => {
        data[name as keyof Product] = value;
        return data;
      }, {} as Partial<Product>);
  }, [product]);

  const hasChangedFields = useMemo(
    () =>
      Object.values(changedFields).length > 0 ||
      selectedCategories.length !== productInit.categories.length ||
      productInit.categories.find(
        category => !selectedCategories.includes(category.id)
      ),
    [changedFields, selectedCategories]
  );

  const hasChangedImage = useMemo(() => image, [image]);

  const categoryById = useMemo(() => {
    return categories.reduce((data, category) => {
      data[category.id] = category;
      return data;
    }, {} as { [id: number]: Category });
  }, []);

  const submit = async () => {
    setError(undefined);
    setLoading(true);
    try {
      let receivedProduct: any;
      if (hasChangedFields) {
        if (productInit.id) {
          const data: any = { ...changedFields };

          const addCategoryIds = selectedCategories.filter(
            id => !productInit.categories.find(category => category.id === id)
          );
          if (addCategoryIds.length) {
            data.addCategoryIds = addCategoryIds;
          }

          const removeCategoryIds = productInit.categories
            .map(category => category.id)
            .filter(id => !selectedCategories.includes(id));
          if (removeCategoryIds.length) {
            data.removeCategoryIds = removeCategoryIds;
          }

          const { data: product } = await axios.patch(
            `/products/${productInit.id.toString()}`,
            data
          );
          receivedProduct = product;
        } else {
          const data: any = { ...changedFields };
          if (selectedCategories.length) {
            data.categoryIds = selectedCategories;
          }
          const { data: product } = await axios.post(`/products`, data);
          receivedProduct = product;
        }
      }
      if (image) {
        const form = new FormData();
        form.append('image', image);
        const { data } = await axios.patch(
          `/products/${(
            productInit.id || receivedProduct.id
          ).toString()}/image`,
          form
        );
        receivedProduct = data;
      }
      onSuccess(receivedProduct);
    } catch (error: any) {
      setError(error);
    }
    setLoading(false);
  };

  return (
    <ModalLayout>
      <Container component="main" maxWidth="xs">
        <div className="edit-product-modal">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              {productInit.id ? <EditIcon /> : <AddIcon />}
            </Avatar>
            <Typography component="h1" variant="h5">
              {productInit.id ? 'Edit' : 'Add'} Product
            </Typography>
            <input
              ref={fileInputRef}
              style={{ display: 'none' }}
              type="file"
              name="file"
              accept="image/*"
              onChange={event => {
                setError(undefined);
                setImage(event.target.files?.[0] ?? undefined);
              }}
            />
            <div
              ref={imageLoaderRef}
              className={classes.image}
              onClick={() => fileInputRef.current?.click()}
              style={{ backgroundImage: `url(${imageSrc})` }}
            >
              <AddIcon
                style={{
                  visibility: image || product.image ? 'hidden' : 'visible',
                }}
                className={classes.add}
              />
            </div>
            <form
              className={classes.form}
              noValidate
              onSubmit={event => event.preventDefault()}
            >
              <TextField
                value={product.name}
                onChange={event => {
                  setError(undefined);
                  setProduct({ ...product, name: event.target.value });
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Product name"
                name="name"
                autoFocus
                autoComplete="none"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                value={product.description}
                onChange={event => {
                  setError(undefined);
                  setProduct({
                    ...product,
                    description: event.target.value,
                  });
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="description"
                label="Description"
                name="description"
                autoComplete="none"
                multiline
                rows={4}
                InputLabelProps={{
                  shrink: true,
                }}
                maxRows={4}
              />

              <TextField
                value={product.guidelines}
                onChange={event => {
                  setError(undefined);
                  setProduct({
                    ...product,
                    guidelines: event.target.value,
                  });
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="guidelines"
                label="Guidelines"
                name="guidelines"
                autoComplete="none"
                multiline
                rows={4}
                InputLabelProps={{
                  shrink: true,
                }}
                maxRows={4}
              />

              <TextField
                value={product.sideEffects}
                onChange={event => {
                  setError(undefined);
                  setProduct({
                    ...product,
                    sideEffects: event.target.value,
                  });
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="sideEffects"
                label="Side Effects"
                name="sideEffects"
                autoComplete="none"
                multiline
                rows={4}
                InputLabelProps={{
                  shrink: true,
                }}
                maxRows={4}
              />

              <TextField
                value={product.price}
                onChange={event => {
                  setError(undefined);
                  setProduct({
                    ...product,
                    price: parseInt(event.target.value, 10),
                  });
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="price"
                label="Product price"
                name="price"
                autoFocus
                autoComplete="none"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                value={product.discount}
                onChange={event => {
                  setError(undefined);
                  setProduct({
                    ...product,
                    discount: parseInt(event.target.value, 10),
                  });
                }}
                variant="outlined"
                margin="normal"
                fullWidth
                id="discount"
                label="Product discount"
                name="discount"
                autoFocus
                autoComplete="none"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <FormControl
                fullWidth
                variant="outlined"
                className={classes.categoriesSelectContainer}
              >
                <InputLabel
                  shrink
                  id="categories-label"
                  className={classes.categoriesLabel}
                >
                  Product categories *
                </InputLabel>
                <Select
                  classes={{
                    select: classes.categoriesSelect,
                  }}
                  variant="outlined"
                  fullWidth
                  multiple
                  label="Product categories * "
                  labelId="categories-label"
                  id="categories"
                  input={
                    <OutlinedInput
                      id="select-multiple-categories"
                      label="Product categories"
                      placeholder="Product categories"
                    />
                  }
                  value={selectedCategories}
                  onChange={event => {
                    setSelectedCategories(event.target.value as number[]);
                  }}
                  renderValue={() => (
                    <div className={classes.chips}>
                      {selectedCategories.map((id, index) => (
                        <Chip
                          key={categoryById[id].id}
                          label={categoryById[id].name}
                          className={classes.chip}
                          onMouseDown={event => {
                            event.stopPropagation();
                          }}
                          onDelete={() => {
                            selectedCategories.splice(index, 1);
                            setSelectedCategories([...selectedCategories]);
                          }}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={menuProps}
                >
                  {categories.map(category => (
                    <MenuItem
                      key={category.name}
                      value={category.id}
                      style={{
                        fontWeight: selectedCategories.find(
                          id => category.id === id
                        )
                          ? theme.typography.fontWeightRegular
                          : theme.typography.fontWeightMedium,
                      }}
                    >
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submit}
                disabled={
                  (!hasChangedFields && !hasChangedImage) ||
                  !product.name ||
                  !product.description ||
                  !product.guidelines ||
                  !product.sideEffects ||
                  !product.price ||
                  !selectedCategories.length ||
                  isLoading
                }
              >
                {isLoading && (
                  <CircularProgress
                    className={classes.progressBar}
                    value={20}
                    size={20}
                    color="secondary"
                  />
                )}
                {productInit.id ? 'Save' : 'Add'}
              </Button>
              {error && (
                <p style={{ color: 'red' }}>
                  {error.response?.status === 413
                    ? 'Image is too big.'
                    : 'There was a problem. Please try again later.'}
                </p>
              )}
            </form>
          </div>
        </div>
      </Container>
    </ModalLayout>
  );
};
