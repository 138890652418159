import React from 'react';
import { Button, Container, makeStyles, Typography } from '@material-ui/core';
import { ModalLayout } from '../../layouts/ModalLayout';

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
  },
  button: {
    marginTop: '10px;',
    marginLeft: '10px;',
    marginRight: '10px;',
  },
}));

interface AskApprovalModalProps {
  action: 'do' | 'delete';
  type:
    | 'action'
    | 'order'
    | 'product'
    | 'category'
    | 'ad'
    | 'offer'
    | 'pharmacy'
    | 'seller'
    | 'transporter'
    | 'editor';
  onAction: (approval: boolean) => void;
}

export const AskApprovalModal: React.FC<AskApprovalModalProps> = ({
  action,
  type,
  onAction,
}: AskApprovalModalProps) => {
  const classes = useStyles();
  const text = `Are you sure you want to ${action} this ${type}?`;
  return (
    <ModalLayout>
      <Container className={classes.container}>
        <Typography>{text}</Typography>
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={() => onAction(true)}
          >
            Yes
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="default"
            onClick={() => onAction(false)}
          >
            No
          </Button>
        </div>
      </Container>
    </ModalLayout>
  );
};
