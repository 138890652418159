import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { AuthContext } from '../contexts/AuthContext';

export const PrivateRoute: React.FC<RouteProps & { component?: React.FC }> = ({
  component: Component,
  children,
  ...rest
}) => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        ) : children ? (
          children
        ) : Component ? (
          <Component />
        ) : (
          <></>
        )
      }
    />
  );
};
