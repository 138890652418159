import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { CloseOutlined, DoneOutlined } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AxiosContext } from '../../contexts/AxiosContext';
import { ModalLayout } from '../../layouts/ModalLayout';
import { Category } from './Category';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(3, 0, 2),
    justifyContent: 'center',
  },
  image: {
    width: '150px',
    height: '150px',
    backgroundColor: 'whitesmoke',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  add: {
    width: '100%',
    height: '100%',
    color: 'lightgray',
    '&:hover': {
      color: 'silver',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  promotionSelectContainer: {
    marginTop: '16px',
  },
  promotionSelectRoot: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '19px !important',
    },
  },
  progressBar: {
    margin: '0 5px',
  },
}));

interface CategoryModalProps {
  category?: Category;
  onSuccess: (category: Category) => void;
}

export const CategoryModal: React.FC<CategoryModalProps> = ({
  category: categoryInit = {
    id: 0,
    name: '',
    description: '',
    image: '',
    promotion: false,
  },
  onSuccess,
}) => {
  const classes = useStyles();
  const axios = useContext(AxiosContext);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const imageLoaderRef = useRef<HTMLDivElement>(null);

  const [isLoading, setLoading] = useState<boolean>();
  const [error, setError] = useState<
    Error & { response?: { status: number } }
  >();

  const [category, setCategory] = useState<Category>(categoryInit);
  const [image, setImage] = useState<File>();
  const [imageAsDataUrl, setImageAsDataUrl] = useState<string>();
  const imageSrc = useMemo(
    () =>
      imageAsDataUrl
        ? imageAsDataUrl
        : categoryInit.image
        ? `${process.env.REACT_APP_PUBLIC_BASE_URL}/categories/${categoryInit.image}`
        : '',
    [imageAsDataUrl, categoryInit]
  );

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onload = event => {
        setImageAsDataUrl(event.target?.result?.toString());
      };
      reader.readAsDataURL(image);
    } else {
      setImageAsDataUrl(undefined);
    }
  }, [image]);

  const changedFields = useMemo(() => {
    return Object.entries(category)
      .filter(
        ([name, value]) =>
          ['name', 'description', 'promotion'].includes(name) &&
          value !== categoryInit[name as keyof Category]
      )
      .reduce((data, [name, value]) => {
        data[name as keyof Category] = value;
        return data;
      }, {} as Partial<Category>);
  }, [category]);

  const hasChangedFields = useMemo(
    () => Object.values(changedFields).length > 0,
    [changedFields]
  );

  const hasChangedImage = useMemo(() => image, [image]);

  const submit = async () => {
    setError(undefined);
    setLoading(true);
    try {
      let receivedCategory: any;
      if (hasChangedFields) {
        if (categoryInit.id) {
          const { data } = await axios.patch(
            `/categories/${categoryInit.id.toString()}`,
            changedFields
          );
          receivedCategory = data;
        } else {
          const { data } = await axios.post(`/categories`, {
            promotion: category.promotion,
            ...changedFields,
          });
          receivedCategory = data;
        }
      }
      if (image) {
        const form = new FormData();
        form.append('image', image);
        const { data } = await axios.patch(
          `/categories/${(
            categoryInit.id || receivedCategory.id
          ).toString()}/image`,
          form
        );
        receivedCategory = data;
      }
      onSuccess(receivedCategory);
    } catch (error: any) {
      setError(error);
    }
    setLoading(false);
  };

  return (
    <ModalLayout>
      <Container component="main" maxWidth="xs">
        <div className="edit-category-modal">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              {categoryInit.id ? <EditIcon /> : <AddIcon />}
            </Avatar>
            <Typography component="h1" variant="h5">
              {categoryInit.id ? 'Edit' : 'Add'} Category
            </Typography>
            <input
              ref={fileInputRef}
              style={{ display: 'none' }}
              type="file"
              name="file"
              accept="image/*"
              onChange={event => {
                setError(undefined);
                setImage(event.target.files?.[0] ?? undefined);
              }}
            />
            <div
              ref={imageLoaderRef}
              className={classes.image}
              onClick={() => fileInputRef.current?.click()}
              style={{ backgroundImage: `url(${imageSrc})` }}
            >
              <AddIcon
                style={{
                  visibility: image || category.image ? 'hidden' : 'visible',
                }}
                className={classes.add}
              />
            </div>
            <form
              className={classes.form}
              noValidate
              onSubmit={event => event.preventDefault()}
            >
              <TextField
                value={category.name}
                onChange={event => {
                  setError(undefined);
                  setCategory({ ...category, name: event.target.value });
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Category name"
                name="name"
                autoFocus
                autoComplete="none"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                value={category.description}
                onChange={event => {
                  setError(undefined);
                  setCategory({
                    ...category,
                    description: event.target.value,
                  });
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="description"
                label="Description"
                name="description"
                autoComplete="none"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                maxRows={4}
              />

              <FormControl
                fullWidth
                variant="outlined"
                className={classes.promotionSelectContainer}
              >
                <InputLabel shrink id="promotion-label">
                  Promotion *
                </InputLabel>
                <Select
                  classes={{
                    root: classes.promotionSelectRoot,
                  }}
                  labelId="promotion-label"
                  id="promotion"
                  label="Promotion * "
                  value={category.promotion}
                  onChange={event => {
                    setError(undefined);
                    setCategory({
                      ...category,
                      promotion: event.target.value as boolean,
                    });
                  }}
                >
                  <MenuItem value="true">
                    <DoneOutlined
                      style={{
                        paddingRight: '10px',
                        fontSize: '28px',
                        color: 'black',
                      }}
                    />
                    <span>Yes</span>
                  </MenuItem>
                  <MenuItem value="false">
                    <CloseOutlined
                      style={{
                        paddingRight: '10px',
                        fontSize: '28px',
                        color: 'black',
                      }}
                    />
                    <span>No</span>
                  </MenuItem>
                </Select>
              </FormControl>

              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submit}
                disabled={
                  (!hasChangedFields && !hasChangedImage) ||
                  !category.name ||
                  !category.description ||
                  isLoading
                }
              >
                {isLoading && (
                  <CircularProgress
                    className={classes.progressBar}
                    value={20}
                    size={20}
                    color="secondary"
                  />
                )}
                {categoryInit.id ? 'Save' : 'Add'}
              </Button>
              {error && (
                <p style={{ color: 'red' }}>
                  {error.response?.status === 413
                    ? 'Image is too big.'
                    : 'There was a problem. Please try again later.'}
                </p>
              )}
            </form>
          </div>
        </div>
      </Container>
    </ModalLayout>
  );
};
