import {
  CloseOutlined,
  DoneOutlined,
  HourglassEmptyOutlined,
} from '@material-ui/icons';
import AirportShuttle from '@material-ui/icons/AirportShuttle';
import React from 'react';

const statusInfo = {
  PENDING: {
    color: 'orange',
    Icon: (styles?: React.CSSProperties) => (
      <HourglassEmptyOutlined style={{ color: 'orange', ...styles }} />
    ),
  },
  ACCEPTED: {
    color: 'green',
    Icon: (styles?: React.CSSProperties) => (
      <DoneOutlined style={{ color: 'green', ...styles }} />
    ),
  },
  CONFIRMING: {
    color: 'orange',
    Icon: (styles?: React.CSSProperties) => (
      <HourglassEmptyOutlined style={{ color: 'orange', ...styles }} />
    ),
  },
  REFUSED: {
    color: 'red',
    Icon: (styles?: React.CSSProperties) => (
      <CloseOutlined style={{ color: 'red', ...styles }} />
    ),
  },
  PICKING: {
    color: 'orange',
    Icon: (styles?: React.CSSProperties) => (
      <HourglassEmptyOutlined style={{ color: 'orange', ...styles }} />
    ),
  },
  DELIVERING: {
    color: 'cadetblue',
    Icon: (styles?: React.CSSProperties) => (
      <AirportShuttle style={{ color: 'cadetblue', ...styles }} />
    ),
  },

  DELIVERED: {
    color: 'green',
    Icon: (styles?: React.CSSProperties) => (
      <DoneOutlined style={{ color: 'green', ...styles }} />
    ),
  },
  DECLINED: {
    color: 'red',
    Icon: (styles?: React.CSSProperties) => (
      <CloseOutlined style={{ color: 'red', ...styles }} />
    ),
  },
  FAILED: {
    color: 'red',
    Icon: (styles?: React.CSSProperties) => (
      <CloseOutlined style={{ color: 'red', ...styles }} />
    ),
  },
};

export default statusInfo;
