export interface Offer {
  id: number;
  name: string;
  description: string;
  discount: number;
  couponCode: string;
  image: string;
  type: OfferType;
  // link: string;
}

export enum OfferType {
  MEDICINES = 'MEDICINES',
  DIAGNOSTICS = 'DIAGNOSTICS',
  CONSULTATIONS = 'CONSULTATIONS',
}
