import {
  createStyles,
  Fab,
  IconButton,
  makeStyles,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
} from '@material-ui/core';
import { AccountCircle, DeleteOutline } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AskApprovalModal } from '../../components/common/AskApprovalModal';
import TablePaginationActions from '../../components/common/TablePaginationActions';
import { AxiosContext } from '../../contexts/AxiosContext';
import DrawerLayout from '../../layouts/DrawerLayout';
import { User } from './User';
import { UserModal } from './UserModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    table: {
      minWidth: 500,
    },
    lastCell: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    text: {
      width: '48px',
      textAlign: 'right',
    },
    void: {
      width: '48px',
    },
    image: {
      width: '70px',
      height: '70px',
      alignItems: 'center',
      borderRadius: '10px',
      color: '#aaa',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    },
  })
);

interface UsersProps {
  type: 'SELLER' | 'TRANSPORTER' | 'EDITOR' | 'SUPPORT';
}

export const Users: React.FC<UsersProps> = ({ type }) => {
  const classes = useStyles();
  const axios = useContext(AxiosContext);

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [search, setSearch] = React.useState<string>('');
  const [selectedId, setSelectedId] = React.useState(0);
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setLoading] = useState<boolean>();
  const [error, setError] = useState<
    Error & { response?: { status: number } }
  >();

  const filteredUsers = useMemo(() => {
    if (search) {
      return users.filter(user =>
        user.email.toLowerCase().includes(search.toLowerCase())
      );
    }
    return users;
  }, [search, users]);

  const [showAddModal, setShowAddModal] = React.useState<boolean>(false);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get('/users', {
        params: {
          role: type,
        },
      })
      .then(({ data }) => {
        setUsers(data);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  const remove = (id: number) => {
    setLoading(true);
    axios
      .delete(`/users/${id}`)
      .then(() => {
        users.splice(
          users.findIndex(user => user.id === id),
          1
        );
        setUsers([...users]);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  };

  return (
    <DrawerLayout
      pageTitle={`${type.charAt(0)}${type.toLowerCase().substring(1)}s`}
    >
      {error ? (
        <p style={{ color: 'red' }}>
          There was a problem. Please try again later.
        </p>
      ) : (
        <TableContainer>
          <Table aria-label="Users table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <TextField
                    value={search}
                    onChange={event => setSearch(event.target.value)}
                    variant="standard"
                    required
                    label="Search..."
                    name="name"
                    autoFocus
                    autoComplete="none"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Password</TableCell>
                <TableCell align="right">
                  <Fab
                    color="primary"
                    aria-label="add"
                    size="small"
                    onClick={() => setShowAddModal(true)}
                  >
                    <AddIcon />
                  </Fab>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map(user => (
                <TableRow key={user.email} className={classes.root}>
                  <TableCell align="left">
                    <AccountCircle className={classes.image} />
                  </TableCell>
                  <TableCell align="center">{user.email}</TableCell>
                  <TableCell align="center">******</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => {
                        setSelectedId(user.id);
                        setShowEditModal(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setSelectedId(user.id);
                        setShowDeleteModal(true);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  align="right"
                  rowsPerPageOptions={[10, 25]}
                  colSpan={6}
                  count={filteredUsers.length}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={(_, page) => setPage(page + 1)}
                  onRowsPerPageChange={event => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(1);
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>

          <Modal open={showAddModal} onClose={() => setShowAddModal(false)}>
            <UserModal
              onSuccess={user => {
                setUsers([...users, user]);
                setShowAddModal(false);
              }}
              type={type}
            />
          </Modal>

          <Modal open={showEditModal} onClose={() => setShowEditModal(false)}>
            <UserModal
              user={users.find(user => user.id === selectedId)}
              onSuccess={user => {
                users[users.findIndex(user => user.id === selectedId)] = user;
                setUsers([...users]);
                setShowEditModal(false);
              }}
              type={type}
            />
          </Modal>

          <Modal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
          >
            <AskApprovalModal
              action="delete"
              type={type.toLowerCase() as 'seller' | 'transporter' | 'editor'}
              onAction={async approved => {
                if (approved) {
                  remove(users.find(user => user.id === selectedId)!.id);
                }
                setShowDeleteModal(false);
              }}
            />
          </Modal>
        </TableContainer>
      )}
    </DrawerLayout>
  );
};
