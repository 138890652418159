import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  makeStyles,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AxiosContext } from '../../contexts/AxiosContext';
import { ModalLayout } from '../../layouts/ModalLayout';
import { Offer, OfferType } from './Offer';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(3, 0, 2),
    justifyContent: 'center',
  },
  image: {
    width: '150px',
    height: '150px',
    backgroundColor: 'whitesmoke',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  add: {
    width: '100%',
    height: '100%',
    color: 'lightgray',
    '&:hover': {
      color: 'silver',
    },
  },
  statusesSelectContainer: {
    marginTop: '16px',
  },
  statusesSelectRoot: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '19px !important',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  progressBar: {
    margin: '0 5px',
  },
}));

interface OfferModalProps {
  offer?: Offer;
  onSuccess: (offer: Offer) => void;
}

export const OfferModal: React.FC<OfferModalProps> = ({
  offer: offerInit = {
    id: 0,
    name: '',
    description: '',
    discount: 0,
    couponCode: '',
    image: '',
    type: OfferType.MEDICINES,
    // link: '',
  },
  onSuccess,
}) => {
  const classes = useStyles();
  const axios = useContext(AxiosContext);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const imageLoaderRef = useRef<HTMLDivElement>(null);

  const [isLoading, setLoading] = useState<boolean>();
  const [error, setError] = useState<
    Error & { response?: { status: number } }
  >();

  const [offer, setOffer] = useState<Offer>(offerInit);
  const [image, setImage] = useState<File>();
  const [imageAsDataUrl, setImageAsDataUrl] = useState<string>();
  const imageSrc = useMemo(
    () =>
      imageAsDataUrl
        ? imageAsDataUrl
        : offerInit.image
        ? `${process.env.REACT_APP_PUBLIC_BASE_URL}/offers/${offerInit.image}`
        : '',
    [imageAsDataUrl, offerInit]
  );

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onload = event => {
        setImageAsDataUrl(event.target?.result?.toString());
      };
      reader.readAsDataURL(image);
    } else {
      setImageAsDataUrl(undefined);
    }
  }, [image]);

  const changedFields = useMemo(() => {
    return Object.entries(offer)
      .filter(
        ([name, value]) =>
          ['name', 'description', 'discount', 'type', 'couponCode'].includes(
            name
          ) && value !== offerInit[name as keyof Offer]
      )
      .reduce((data, [name, value]) => {
        data[name as keyof Offer] = value;
        return data;
      }, {} as Partial<Offer>);
  }, [offer]);

  const hasChangedFields = useMemo(
    () => Object.values(changedFields).length > 0,
    [changedFields]
  );

  const hasChangedImage = useMemo(() => image, [image]);

  const submit = async () => {
    setError(undefined);
    setLoading(true);
    try {
      let receivedOffer: any;
      if (hasChangedFields) {
        if (offerInit.id) {
          const { data } = await axios.patch(
            `/offers/${offerInit.id.toString()}`,
            changedFields
          );
          receivedOffer = data;
        } else {
          const { data } = await axios.post(`/offers`, {
            discount: offer.discount,
            type: offer.type,
            ...changedFields,
          });
          receivedOffer = data;
        }
      }
      if (image) {
        const form = new FormData();
        form.append('image', image);
        const { data } = await axios.patch(
          `/offers/${(offerInit.id || receivedOffer.id).toString()}/image`,
          form
        );
        receivedOffer = data;
      }
      onSuccess(receivedOffer);
    } catch (error: any) {
      setError(error);
    }
    setLoading(false);
  };

  return (
    <ModalLayout>
      <Container component="main" maxWidth="xs">
        <div className="edit-offer-modal">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              {offerInit.id ? <EditIcon /> : <AddIcon />}
            </Avatar>
            <Typography component="h1" variant="h5">
              {offerInit.id ? 'Edit' : 'Add'} Offer
            </Typography>
            <input
              ref={fileInputRef}
              style={{ display: 'none' }}
              type="file"
              name="file"
              accept="image/*"
              onChange={event => {
                setError(undefined);
                setImage(event.target.files?.[0] ?? undefined);
              }}
            />
            <div
              ref={imageLoaderRef}
              className={classes.image}
              onClick={() => fileInputRef.current?.click()}
              style={{ backgroundImage: `url(${imageSrc})` }}
            >
              <AddIcon
                style={{
                  visibility: image || offer.image ? 'hidden' : 'visible',
                }}
                className={classes.add}
              />
            </div>
            <form
              className={classes.form}
              noValidate
              onSubmit={event => event.preventDefault()}
            >
              <TextField
                value={offer.name}
                onChange={event => {
                  setError(undefined);
                  setOffer({ ...offer, name: event.target.value });
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Offer name"
                name="name"
                autoFocus
                autoComplete="none"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                value={offer.description}
                onChange={event => {
                  setError(undefined);
                  setOffer({
                    ...offer,
                    description: event.target.value,
                  });
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="description"
                label="Description"
                name="description"
                autoComplete="none"
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
                maxRows={4}
              />
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.statusesSelectContainer}
              >
                <InputLabel shrink id="type-label">
                  Type *
                </InputLabel>
                <Select
                  classes={{
                    root: classes.statusesSelectRoot,
                  }}
                  labelId="type-label"
                  id="types"
                  label="Type * "
                  value={offer.type.toString()}
                  onChange={event =>
                    setOffer({
                      ...offer,
                      type: event.target.value as OfferType,
                    })
                  }
                >
                  <MenuItem value={'MEDICINES'}>Medicines</MenuItem>
                  <MenuItem value={'DIAGNOSTICS'}>Diagnostics</MenuItem>
                  <MenuItem value={'CONSULTATIONS'}>Consultations</MenuItem>
                </Select>
              </FormControl>

              <TextField
                type="number"
                value={offer.discount}
                onChange={event => {
                  setError(undefined);
                  setOffer({
                    ...offer,
                    discount: Number(event.target.value),
                  });
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="discount"
                label="Discount"
                name="discount"
                autoComplete="none"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                value={offer.couponCode}
                onChange={event => {
                  setError(undefined);
                  setOffer({ ...offer, couponCode: event.target.value });
                }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="couponCode"
                label="Coupon Code"
                name="couponCode"
                inputProps={{
                  maxLength: '10',
                }}
                autoComplete="none"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submit}
                disabled={
                  (!hasChangedFields && !hasChangedImage) ||
                  !offer.name ||
                  !offer.description ||
                  !offer.couponCode ||
                  isLoading
                }
              >
                {isLoading && (
                  <CircularProgress
                    className={classes.progressBar}
                    value={20}
                    size={20}
                    color="secondary"
                  />
                )}
                {offerInit.id ? 'Save' : 'Add'}
              </Button>
              {error && (
                <p style={{ color: 'red' }}>
                  There was a problem. Please try again later.
                </p>
              )}
            </form>
          </div>
        </div>
      </Container>
    </ModalLayout>
  );
};
