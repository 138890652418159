import React, { useContext, useState } from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  makeStyles,
  TextField,
  Typography,
  Container,
} from '@material-ui/core';
import { ModalLayout } from '../../layouts/ModalLayout';
import LockIcon from '@material-ui/icons/Lock';
import { AxiosContext } from '../../contexts/AxiosContext';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const INVALID_REPEAT_PASSWORD = 'Repeated password is not the same.';

interface CredentialsModalProps {
  close: () => void;
}

export const CredentialsModal: React.FC<CredentialsModalProps> = ({
  close,
}) => {
  const classes = useStyles();

  const axios = useContext(AxiosContext);

  const [password, setPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [repeatNewPassword, setRepeatNewPassword] = useState<string>('');

  const [error, setError] = useState<
    Error & { response?: { status: number } }
  >();
  const [loading, setLoading] = useState<boolean>();

  const submit = () => {
    if (newPassword !== repeatNewPassword) {
      setError(new Error(INVALID_REPEAT_PASSWORD));
    } else {
      setLoading(true);
      setError(undefined);
      axios
        .post('/authentication/change-password', {
          currentPassword: password,
          newPassword: newPassword,
        })
        .then(() => {
          setLoading(false);
          close();
        })
        .catch(error => {
          setError(error);
          setLoading(false);
        });
    }
  };

  return (
    <ModalLayout>
      <Container component="main" maxWidth="xs">
        <div className="credentials-modal">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Credentials
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                value={password}
                onChange={event => {
                  setError(undefined);
                  setPassword(event.target.value);
                }}
                type="password"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Current password"
                id="current_password"
                name="current_password"
                autoFocus
                autoComplete="none"
              />
              <TextField
                value={newPassword}
                onChange={event => {
                  setError(undefined);
                  setNewPassword(event.target.value);
                }}
                type="password"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="New password"
                id="password"
                autoComplete="none"
              />
              <TextField
                value={repeatNewPassword}
                onChange={event => {
                  setError(undefined);
                  setRepeatNewPassword(event.target.value);
                }}
                type="password"
                required
                variant="outlined"
                margin="normal"
                fullWidth
                name="password_repeat"
                label="Repeat new password"
                id="password_repeat"
                autoComplete="none"
              />

              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submit}
                disabled={
                  !!error ||
                  loading ||
                  password.length < 8 ||
                  password.length > 30 ||
                  newPassword.length < 8 ||
                  newPassword.length > 30 ||
                  repeatNewPassword.length < 8 ||
                  repeatNewPassword.length > 30
                }
              >
                Save
              </Button>
              {error && (
                <p style={{ color: 'red' }}>
                  {error.response?.status === 400
                    ? 'Invalid credentials.'
                    : error.message === INVALID_REPEAT_PASSWORD
                    ? INVALID_REPEAT_PASSWORD
                    : 'There was a problem. Please try again later.'}
                </p>
              )}
            </form>
          </div>
        </div>
      </Container>
    </ModalLayout>
  );
};
