import {
  Button,
  Collapse,
  createStyles,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  Apps,
  CloseOutlined,
  CommentOutlined,
  DeleteOutline,
  DoneOutlined,
  PauseOutlined,
  PlayArrow,
  Star,
  StarOutline,
} from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import Redeem from '@material-ui/icons/Redeem';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import { AskApprovalModal } from '../../components/common/AskApprovalModal';
import TablePaginationActions from '../../components/common/TablePaginationActions';
import { AuthContext } from '../../contexts/AuthContext';
import { AxiosContext } from '../../contexts/AxiosContext';
import { SoundContext } from '../../contexts/SoundContext';
import DrawerLayout from '../../layouts/DrawerLayout';
import { User } from '../users/User';
import { FeedbackModal } from './FeedbackModal';
import { Order, Status } from './Order';
import { OrderModal } from './OrderModal';
import statusInfo from './statusInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
    table: {
      minWidth: 500,
    },
    lastCell: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    text: {
      width: '48px',
      textAlign: 'right',
    },
    void: {
      width: '48px',
    },
    image: {
      width: '70px',
      height: '70px',
      backgroundColor: 'lightgrey',
      alignItems: 'center',
      borderRadius: '10px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    },
    categoryTag: {
      backgroundColor: 'lightgrey',
      borderRadius: '15px',
      paddingTop: '6px;',
      paddingBottom: '6px;',
      paddingLeft: '10px;',
      paddingRight: '10px;',
      marginRight: '5px',
      display: 'inline',
      '&:last-child': {
        marginRight: '0px',
      },
    },
    statusContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    statusesSelectContainer: {
      marginTop: '16px',
    },
    statusesSelectRoot: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        fontSize: '19px !important',
      },
    },
  })
);

interface Pagination {
  pages: number;
  page: number;
  size: number;
  length: number;
}

export const Orders: React.FC = () => {
  var audio = new window.Audio(
    process.env.PUBLIC_URL + '/sounds/notification-sound.mp3'
  );

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const classes = useStyles();
  const sound = useContext(SoundContext);
  const axios = useContext(AxiosContext);
  const { role } = useContext(AuthContext);

  const [page, setPage] = React.useState(() =>
    params.has('page') ? parseInt(params.get('page')!, 10) : 1
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(() =>
    params.has('size') ? parseInt(params.get('size')!, 10) : 10
  );

  const [search, setSearch] = React.useState<string>(() =>
    decodeURIComponent(params.get('search') ?? '')
  );
  const [selectedId, setSelectedId] = React.useState(0);
  const [pagination, setPagination] = React.useState<Pagination>({
    pages: 0,
    page: 1,
    size: 0,
    length: 0,
  });
  const [refresh, setRefresh] = useState<number>(() => Date.now());
  const [timestamp, setTimestamp] = useState<number>(() => Date.now());
  const [transporters, setTransporters] = useState<User[]>([]);
  const [orders, setOrders] = useState<Order[]>([]);
  const [lastOrderId, setLastOrderId] = useState<number>();
  const [isLoadingOrders, setIsLoadingOrders] = useState<boolean>(true);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true);
  const isLoading = useMemo(() => {
    return isLoadingOrders || isLoadingUsers;
  }, [isLoadingOrders, isLoadingUsers]);
  const [error, setError] = useState<
    Error & { response?: { status: number } }
  >();

  const [status, setStatus] = useState<Status | 'ALL' | 'ACTIVE' | 'INACTIVE'>(
    (params.get('status') as Status | 'ALL' | 'ACTIVE' | 'INACTIVE' | null) ??
      'ALL'
  );

  const [showProductsList, setShowProductsList] =
    React.useState<boolean>(false);

  const [showFeedbackModal, setShowFeedbackModal] =
    React.useState<boolean>(false);
  const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
  const [showApproveModal, setShowApproveModal] =
    React.useState<boolean>(false);
  const [showDeclineModal, setShowDeclineModal] =
    React.useState<boolean>(false);
  const [showTransporterModal, setShowTransporterModal] =
    React.useState<boolean>(false);
  const [showNewOrdersMessage, setShowNewOrdersMessage] = useState(false);

  useEffect(() => {
    const newParams: Record<string, string> = {
      page: page.toString(),
      size: rowsPerPage.toString(),
      status,
    };
    if (search) {
      newParams.search = encodeURIComponent(search);
    }
    history.replace({
      pathname: '/orders',
      search: new URLSearchParams(newParams).toString(),
    });
  }, [page, rowsPerPage, search, status]);

  useEffect(() => {
    setIsLoadingUsers(true);
    axios
      .get('/users?role=TRANSPORTER')
      .then(({ data }) => {
        setTransporters(data);
      })
      .catch(setError)
      .finally(() => setIsLoadingUsers(false));
  }, []);

  useEffect(() => {
    setIsLoadingOrders(true);
    axios
      .get('/orders', {
        params: {
          page,
          size: rowsPerPage,
          status,
          search: search ? search : undefined,
        },
      })
      .then(({ data: { pagination, data } }) => {
        setOrders(data);
        setPagination(pagination);
        if (data.length > 0 && pagination.page === 1) {
          setLastOrderId(data[0].id);
        }
      })
      .catch(setError)
      .finally(() => setIsLoadingOrders(false));
  }, [page, rowsPerPage, refresh, search, status]);

  useEffect(() => {
    const timer = setInterval(() => {
      axios
        .get('/orders', {
          params: {
            page: 1,
            size: 10,
          },
        })
        .then(({ data: { data } }) => {
          if (data.length > 0 && lastOrderId !== data[0].id) {
            if (page === 1) {
              setRefresh(() => Date.now());
            } else {
              setLastOrderId(data[0].id);
            }
            setShowNewOrdersMessage(true);
            if (sound.soundOn) {
              audio.play();
            }
          }
        });
    }, 5000);
    return () => clearInterval(timer);
  }, [lastOrderId, page, sound.soundOn]);

  const remove = (id: number) => {
    setIsLoadingOrders(true);
    return axios
      .delete(`/orders/${id}`)
      .then(() => {
        orders.splice(
          orders.findIndex(order => order.id === id),
          1
        );
        setOrders([...orders]);
      })
      .catch(setError)
      .finally(() => setIsLoadingOrders(false));
  };

  const update = (id: number, data: { status: Status }) => {
    setIsLoadingOrders(true);
    return axios
      .patch(`/orders/${id}`, data)
      .then(({ data: order }) => {
        const index = orders.findIndex(order => order.id === id);
        orders[index] = order;
        setOrders([...orders]);
      })
      .catch(setError)
      .finally(() => setIsLoadingOrders(false));
  };
  const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleMessageClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowNewOrdersMessage(false);
  };

  const onCheckNewOrdersOutClick = () => {
    setPage(1);
    setShowNewOrdersMessage(false);
    window.scroll({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <DrawerLayout pageTitle="Orders">
      {error ? (
        <p style={{ color: 'red' }}>
          There was a problem. Please try again later.
        </p>
      ) : (
        <TableContainer>
          <Snackbar
            open={showNewOrdersMessage}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={handleMessageClose}
          >
            <Alert
              onClose={handleMessageClose}
              severity="success"
              sx={{ width: '100%' }}
            >
              {page === 1 ? (
                <Typography>New orders placed</Typography>
              ) : (
                <Typography>
                  New orders placed
                  <Button color="primary" onClick={onCheckNewOrdersOutClick}>
                    Check out
                  </Button>
                </Typography>
              )}
            </Alert>
          </Snackbar>
          <Table aria-label="Orders table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <TextField
                    value={search}
                    onChange={event => {
                      setPage(1);
                      setSearch(event.target.value);
                    }}
                    variant="standard"
                    required
                    label="Search..."
                    name="name"
                    autoFocus
                    autoComplete="none"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <p style={{ paddingLeft: '15px' }}>Status</p>
                </TableCell>
                <TableCell align="center">Transporter</TableCell>
                <TableCell align="center">Pharmacy</TableCell>
                <TableCell align="center">Phone number</TableCell>
                <TableCell align="center">Address</TableCell>
                <TableCell align="center">Price</TableCell>
                <TableCell align="center">Rating</TableCell>
                <TableCell align="center">
                  <FormControl
                    fullWidth
                    variant="standard"
                    className={classes.statusesSelectContainer}
                  >
                    <InputLabel shrink id="statuses-label">
                      Status *
                    </InputLabel>
                    <Select
                      classes={{
                        root: classes.statusesSelectRoot,
                      }}
                      labelId="statuses-label"
                      id="statuses"
                      label="Status * "
                      value={status}
                      onChange={event =>
                        setStatus(
                          event.target.value as
                            | Status
                            | 'ALL'
                            | 'ACTIVE'
                            | 'INACTIVE'
                        )
                      }
                    >
                      <MenuItem value="ALL">
                        <Apps
                          style={{ paddingRight: '10px', fontSize: '28px' }}
                        />
                        <span>ALL</span>
                      </MenuItem>
                      {Object.values(Status).map(status => (
                        <MenuItem key={status} value={status}>
                          {statusInfo[status].Icon({
                            paddingRight: '10px',
                            fontSize: '28px',
                            color: 'black',
                          })}
                          <span>{status}</span>
                        </MenuItem>
                      ))}
                      <MenuItem value="ACTIVE">
                        <PauseOutlined
                          style={{ paddingRight: '10px', fontSize: '28px' }}
                        />
                        <span>ACTIVE</span>
                      </MenuItem>
                      <MenuItem value="INACTIVE">
                        <PlayArrow
                          style={{ paddingRight: '10px', fontSize: '28px' }}
                        />
                        <span>INACTIVE</span>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && orders.length === 0 ? (
                <p style={{ color: 'orange' }}>There are no orders.</p>
              ) : (
                orders.map(order => (
                  <>
                    <TableRow key={order.id} className={classes.root}>
                      <TableCell align="left">
                        <p style={{ fontSize: '25px' }}>
                          #{order.id.toString().padStart(7, '0')}
                        </p>
                      </TableCell>
                      <TableCell align="left">
                        <div className={classes.statusContainer}>
                          {statusInfo[order.status].Icon()}

                          <p
                            style={{
                              fontWeight: 'bold',
                              paddingLeft: '5px',
                              color: statusInfo[order.status].color,
                            }}
                          >
                            {order.status}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {order.transporter?.email ?? ''}
                      </TableCell>
                      <TableCell align="center">
                        {order.pharmacy?.name}
                      </TableCell>
                      <TableCell align="center">{order.phoneNumber}</TableCell>
                      <TableCell align="center">{order.address}</TableCell>
                      <TableCell align="center">
                        <p style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                          {order.price}L
                        </p>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {order.feedback &&
                          Array(5)
                            .fill(0)
                            .map((_, index) =>
                              index < order.feedback!.rating! ? (
                                <Star style={{ color: 'orange' }} key={index} />
                              ) : (
                                <StarOutline
                                  style={{ color: 'orange' }}
                                  key={index}
                                />
                              )
                            )}
                      </TableCell>
                      <TableCell align="right">
                        {((['ADMIN', 'SELLER'].includes(role) &&
                          order.status === Status.PENDING) ||
                          (['ADMIN', 'TRANSPORTER'].includes(role) &&
                            [
                              Status.CONFIRMING,
                              Status.PICKING,
                              Status.DELIVERING,
                            ].includes(order.status))) && (
                          <>
                            <IconButton
                              style={{ color: 'green' }}
                              onClick={() => {
                                setSelectedId(order.id);
                                setShowApproveModal(true);
                              }}
                            >
                              <DoneOutlined />
                            </IconButton>
                            <IconButton
                              style={{ color: 'red' }}
                              onClick={() => {
                                setSelectedId(order.id);
                                setShowDeclineModal(true);
                              }}
                            >
                              <CloseOutlined />
                            </IconButton>
                          </>
                        )}
                        {order.feedback && (
                          <IconButton
                            onClick={() => {
                              setSelectedId(order.id);
                              setShowFeedbackModal(true);
                            }}
                          >
                            <CommentOutlined />
                          </IconButton>
                        )}
                        <IconButton
                          onClick={() => {
                            if (order.id !== selectedId) {
                              setShowProductsList(true);
                            } else {
                              setShowProductsList(!showProductsList);
                            }
                            setSelectedId(order.id);
                          }}
                        >
                          <Redeem />
                        </IconButton>
                        {['SELLER', 'SUPPORT', 'ADMIN'].includes(role) && (
                          <IconButton
                            onClick={() => {
                              setSelectedId(order.id);
                              setShowEditModal(true);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                        {role === 'ADMIN' && (
                          <IconButton
                            onClick={() => {
                              setSelectedId(order.id);
                              setShowDeleteModal(true);
                            }}
                          >
                            <DeleteOutline />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                    {order.id === selectedId && showProductsList && (
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={8}
                        >
                          <Collapse in={true} timeout="auto" unmountOnExit>
                            <Table aria-label="Orders table">
                              <TableHead>
                                <TableRow>
                                  <TableCell />
                                  <TableCell align="center">
                                    Product Name
                                  </TableCell>
                                  <TableCell align="center">Quantity</TableCell>
                                  <TableCell align="center" />
                                  <TableCell align="center">Price</TableCell>
                                  <TableCell align="center">Total</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {order.quantities.map(
                                  ({ product, quantity }) => (
                                    <TableRow key={product.id}>
                                      <TableCell align="center">
                                        <div
                                          className={classes.image}
                                          style={{
                                            backgroundImage: `url(${
                                              product.image
                                                ? `${process.env.REACT_APP_PUBLIC_BASE_URL}/products/${product.image}`
                                                : ''
                                            })`,
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell align="center">
                                        <p style={{ whiteSpace: 'nowrap' }}>
                                          {product.name}
                                        </p>
                                      </TableCell>
                                      <TableCell align="center">
                                        {quantity}
                                      </TableCell>
                                      <TableCell align="center">x</TableCell>
                                      <TableCell align="center">
                                        <p
                                          style={{
                                            whiteSpace: 'nowrap',
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {product.price}L
                                        </p>
                                      </TableCell>
                                      <TableCell align="center">
                                        <p
                                          style={{
                                            whiteSpace: 'nowrap',
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {quantity * product.price}L
                                        </p>
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                                <TableRow id="total">
                                  <TableCell align="center" />
                                  <TableCell align="center" />
                                  <TableCell align="center" />
                                  <TableCell align="center" />
                                  <TableCell align="center">Total</TableCell>
                                  <TableCell align="center">
                                    <p
                                      style={{
                                        whiteSpace: 'nowrap',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {order.price}L
                                    </p>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  align="right"
                  rowsPerPageOptions={[10, 25]}
                  colSpan={8}
                  count={pagination.length}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onPageChange={(_, page) => {
                    setPage(page + 1);
                  }}
                  onRowsPerPageChange={event => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(1);
                  }}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>

          <Modal
            open={showFeedbackModal}
            onClose={() => setShowFeedbackModal(false)}
          >
            <FeedbackModal
              order={orders.find(order => order.id === selectedId)!}
              onClose={() => setShowFeedbackModal(false)}
            />
          </Modal>

          <Modal open={showEditModal} onClose={() => setShowEditModal(false)}>
            <OrderModal
              order={orders.find(order => order.id === selectedId)!}
              transporters={transporters}
              onSuccess={order => {
                orders[orders.findIndex(order => order.id === selectedId)] =
                  order;
                setOrders([...orders]);
                setShowEditModal(false);
                setTimestamp(Date.now());
              }}
            />
          </Modal>

          <Modal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
          >
            <AskApprovalModal
              action="delete"
              type="order"
              onAction={async approved => {
                if (approved) {
                  remove(orders.find(order => order.id === selectedId)!.id);
                }
                setShowDeleteModal(false);
              }}
            />
          </Modal>

          <Modal
            open={showApproveModal}
            onClose={() => setShowApproveModal(false)}
          >
            <AskApprovalModal
              action="do"
              type="action"
              onAction={async approved => {
                if (approved) {
                  const order = orders.find(order => order.id === selectedId)!;
                  let status = order.status;
                  if (status === Status.PENDING) {
                    status = Status.ACCEPTED;
                  } else if (status === Status.CONFIRMING) {
                    status = Status.PICKING;
                  } else if (status === Status.PICKING) {
                    status = Status.DELIVERING;
                  } else if (status === Status.DELIVERING) {
                    status = Status.DELIVERED;
                  }
                  await update(order.id, { status });
                }
                setShowApproveModal(false);
              }}
            />
          </Modal>

          <Modal
            open={showDeclineModal}
            onClose={() => setShowDeclineModal(false)}
          >
            <AskApprovalModal
              action="do"
              type="action"
              onAction={async approved => {
                if (approved) {
                  const order = orders.find(order => order.id === selectedId)!;
                  let status = order.status;
                  if (status === Status.PENDING) {
                    status = Status.DECLINED;
                  } else if (status === Status.CONFIRMING) {
                    status = Status.REFUSED;
                  } else if (status === Status.PICKING) {
                    status = Status.FAILED;
                  } else if (status === Status.DELIVERING) {
                    status = Status.FAILED;
                  }
                  await update(order.id, { status });
                }
                setShowDeclineModal(false);
              }}
            />
          </Modal>
        </TableContainer>
      )}
    </DrawerLayout>
  );
};
